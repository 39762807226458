import { default as classnames } from 'classnames';
import { XCircle, MagicWand as AutoArrangeIcon } from '@phosphor-icons/react';
import { path } from 'ramda';
import {
    type KeyboardEventHandler,
    type KeyboardEvent,
    type MouseEvent,
    useRef,
    useState,
} from 'react';
import { connect } from 'react-redux';
import '../../../../../../css/graph/group-element.less';
import { addNotification as addNotificationAction } from '../../../../../js/actions/reduxActions/notification';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import MapElementIcon from '../../../icons/MapElementIcon';
import UngroupIcon from '../../../icons/UngroupIcon';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import {
    executeIfNotDragging,
    isArrowKeyPressed,
    isColorFillMode,
    isSelectedOrNothingSelected,
} from '../../../../../js/components/graph/utils';
import { getElementStyles } from '../../../../../js/components/graph/elements/elementStyles';
import { useAuth } from '../../../AuthProvider';
import type { GroupElementAPI } from '../../../../sources/graph';
import type { AddNotification, Dragging, SwimlaneElementStyles } from '../../../../types';

export interface Props {
    onOver: () => void;
    onOut: () => void;
    onDoubleClick: (
        event: MouseEvent<SVGGElement> | KeyboardEvent<SVGGElement>,
        usingKeyboard?: boolean,
    ) => void;
    onDelete: (() => void) | undefined;
    isSelected: boolean;
    isHovered: boolean;
    resizeHandles: JSX.Element[];
    dragging: Dragging;
    isDragging: boolean;
    draggingOver: boolean;
    height: number;
    width: number;
    x: number;
    y: number;
    developerName: string;
    elementType: string;
    id: string;
    flowId: string;
    groupRef: React.RefObject<SVGGElement>;
    startDrag: (
        event: MouseEvent<SVGGElement> | KeyboardEvent<SVGGElement>,
        usingKeyboard?: boolean,
    ) => void;
    onSelect: (
        e: MouseEvent<HTMLSpanElement> | MouseEvent<SVGGElement> | KeyboardEvent<SVGGElement>,
        ids: string[],
    ) => void;
    groupElement: GroupElementAPI;
    addNotification: AddNotification;
    isOutOfSearch: boolean;
    onCancelDrag: () => void;
    keyboardDragging: boolean;
    selectedElementIds: string[];
    zoomLevel: number;
    movingNewElementWithKeyboard?: boolean;
    onToggleContextMenu: (toggle?: boolean) => void;
    onAutoArrangeGroup: () => void;
}

const GroupElementBase = ({
    onOver,
    onOut,
    onDoubleClick,
    onDelete,
    isSelected,
    isHovered,
    resizeHandles,
    dragging,
    isDragging,
    draggingOver,
    height,
    width,
    x,
    y,
    developerName: initialDeveloperName,
    elementType,
    id,
    flowId,
    groupRef,
    startDrag,
    onSelect,
    groupElement,
    addNotification,
    isOutOfSearch,
    onCancelDrag,
    keyboardDragging,
    selectedElementIds,
    zoomLevel,
    movingNewElementWithKeyboard,
    onToggleContextMenu,
    onAutoArrangeGroup,
}: Props) => {
    const {
        setGroupNameEditing,
        blockHotkeys,
        ungroup,
        saveGroupElement,
        highlightGroupElement,
    }: {
        setGroupNameEditing: (state: boolean) => void;
        blockHotkeys: boolean;
        ungroup: (data: { selectedGroupElementId: string }) => void;
        saveGroupElement: (group: GroupElementAPI) => void;
        highlightGroupElement: (group: GroupElementAPI) => void;
    } = useGraph();

    const [editingName, setEditingNameState] = useState(false);
    const setEditingName = (state: boolean) => {
        setGroupNameEditing(state);
        setEditingNameState(state);
    };

    const { userSettings } = useAuth();
    const { canvasSettings } = userSettings;

    const [editMouseDown, setEditMouseDown] = useState(false);
    const [keyboardInUse, setKeyboardInUse] = useState(false);

    const nameRef = useRef<HTMLSpanElement>(null);

    const selectGroupElement = (
        e: MouseEvent<HTMLSpanElement> | MouseEvent<SVGGElement> | KeyboardEvent<SVGGElement>,
    ) => {
        onSelect(e, selectedElementIds);
    };

    const draggingProps = editingName
        ? {}
        : {
              onMouseDown: startDrag,
              onMouseUp: selectGroupElement,
              onMouseEnter: onOver,
              onFocus: onOver,
              onDoubleClick,
          };

    const styles = getElementStyles(elementType) as SwimlaneElementStyles;

    const className = classnames({
        hover: isHovered,
        drag: isDragging,
        select: isSelected,
        'out-of-search': isOutOfSearch,
        [elementType]: true,
        'no-focus-border': true,
        'keyboard-focused-group': keyboardInUse && !isSelected,
        // Selected focused border if an element is the only one selected or if it's being dragged regardless of other selected elements.
        'keyboard-focused-selected':
            keyboardInUse &&
            ((isSelected && (isSelectedOrNothingSelected(id, selectedElementIds) as boolean)) ||
                isDragging),
        'new-element-keyboard-drag': movingNewElementWithKeyboard, // New group elements being dragged on from the sidebar with the keyboard
    });

    const saveName = () => {
        setEditingName(false);
        setEditMouseDown(false);
        // clear text selection for Firefox
        window?.getSelection()?.removeAllRanges();
        if (!nameRef.current) {
            return;
        }
        if (nameRef.current.textContent) {
            // Only save if they changed the name
            if (nameRef.current.textContent !== initialDeveloperName) {
                saveGroupElement({
                    ...groupElement,
                    developerName: nameRef.current.textContent,
                });
            }
        } else {
            // Using the ref to set the content-editable span's content
            // because React does not update content-editable components correctly
            nameRef.current.textContent = initialDeveloperName;
            addNotification({
                type: 'error',
                message: 'Groups cannot be given an empty name',
                isPersistent: true,
            });
        }
    };

    const onUngroup = () => ungroup({ selectedGroupElementId: id });

    const keyUp = (event: KeyboardEvent) => {
        // Modifier keys shouldn't turn on keyboard controls as they are used when mouse clicking
        if (event.key !== 'Control' && event.key !== 'Shift') {
            setKeyboardInUse(true);
        }
    };

    const keyDown: KeyboardEventHandler<SVGGElement> = (event) => {
        if (event.key === 'Enter' && editingName) {
            saveName();
        } else if (blockHotkeys) {
            return;
        } else if (event.key === 'Escape') {
            onCancelDrag();
            onToggleContextMenu(true);
        } else if (!event.shiftKey && event.key === ' ') {
            onDoubleClick(event, true);
        } else if (event.key === 'ContextMenu') {
            onToggleContextMenu();
        }
        // If the element is focused/selected and nothing else is selected then the arrow keys should start moving the element.
        else if (
            !editingName &&
            (event.key === 'Enter' ||
                (isArrowKeyPressed(event) && isSelectedOrNothingSelected(id, selectedElementIds)))
        ) {
            selectGroupElement(event);
            startDrag(event, true);
        } else if (event.key === 'l') {
            highlightGroupElement(groupElement);
        }
    };

    return (
        <g
            id={id}
            className={className}
            transform={`matrix(1,0,0,1,${x},${y})`}
            ref={groupRef}
            onMouseLeave={onOut}
            height={height}
            width={width}
            tabIndex={-1}
            onKeyDown={keyDown}
            onKeyUp={keyUp}
            onMouseEnter={() => setKeyboardInUse(false)}
            aria-label={`Element of type ${elementType} with the name: ${
                groupElement?.developerName ?? 'unnamed'
            }`}
        >
            {/* Grey selection border to help indicate selection/focus */}
            {isSelected && (!isDragging || keyboardDragging) && (
                <rect
                    width={width + 5 * 2}
                    height={height + 5 * 2}
                    {...styles.outBorder}
                    data-testid="map-element-selected"
                />
            )}
            {/* Border around the whole group */}
            <rect
                className="group-element-border"
                stroke={styles.border.fill}
                strokeOpacity={1}
                rx={4}
                strokeWidth={canvasSettings.lineThickness}
                pointerEvents="none"
                fill={`url(#dots-${flowId})`}
                fillOpacity={0.5}
                height={height}
                width={width}
                data-testid={`border-${id}`}
            />
            <g
                onMouseDown={startDrag}
                onMouseUp={selectGroupElement}
                onMouseEnter={onOver}
                onFocus={onOver}
                onDoubleClick={onDoubleClick}
                data-testid="groupElement"
            >
                {/* Square bottom to group header */}
                <rect
                    fill={styles.border.fill}
                    y={20}
                    height={10}
                    width={width}
                    className="group-element-drag-handle"
                />
                {/* Rounded header */}
                <rect
                    rx={4}
                    fill={styles.border.fill}
                    height={styles.header.height}
                    width={width}
                    className="group-element-drag-handle"
                />
            </g>
            {/* Green dragging square around the contents to highlight when a map element is being dragging into this group */}
            {draggingOver && (
                <rect
                    strokeWidth="2"
                    stroke="#0f0"
                    fill="none"
                    x={-2}
                    y={-2 + styles.header.height}
                    height={height - styles.header.height + 4}
                    width={width + 4}
                />
            )}
            {/* Group name, icon and content */}
            <foreignObject
                x={5}
                y={5}
                width={width - 10}
                height={20}
                {...draggingProps}
                className="group-element-drag-handle"
            >
                <span className="map-element-content">
                    <MapElementIcon
                        elementType={elementType}
                        className={classnames({
                            'map-element-icon': true,
                            'map-element-no-actions': elementType === MAP_ELEMENT_TYPES.swimlane,
                            'color-white': isColorFillMode(
                                canvasSettings.mapElementColorStyle,
                                zoomLevel,
                            ),
                        })}
                        size={15}
                    />
                    <span className={`group-element-text ${editingName ? 'editing' : ''}`}>
                        <span
                            suppressContentEditableWarning={true}
                            data-testid="editable-name"
                            ref={nameRef}
                            // biome-ignore lint/a11y/useSemanticElements: Requires refactor
                            role="textbox"
                            tabIndex={-1}
                            // If they mouse down on this after selection
                            onMouseDown={() => {
                                if (isSelected) {
                                    setEditMouseDown(true);
                                }
                            }}
                            // and then mouse up on this after that, then we can be sure they want to edit the name
                            onMouseUp={(e) => {
                                if (editMouseDown) {
                                    setEditingName(true);
                                    setEditMouseDown(false);

                                    // If they've only just started editing
                                    if (editingName === false) {
                                        // then also select all the text in the group so they can rename easier
                                        window.getSelection()?.selectAllChildren(e.target as Node);
                                    }
                                }
                                selectGroupElement(e);
                                // Don't block dragging.hasMovedEnough events,
                                // as those need to propagate to OnDragEnd to end the drag
                                if (!dragging) {
                                    // Stop this mouseUp event from propagating up if the user isn't dragging,
                                    // because if the graph gets this event, it'll deselect everything
                                    e.stopPropagation();
                                }
                            }}
                            onBlur={saveName}
                            // Stop propagation so DELETE key doesn't delete group
                            onKeyUp={(e) => e.stopPropagation()}
                            // Stop propagation so clicking to edit doesn't edit group
                            onDoubleClick={(e) => e.stopPropagation()}
                            // If they move the mouse during editing, then stop propagation of that event so the element doesn't move
                            // If they move the mouse after mouse down, then unset mouse down, as they probably want to drag and not edit
                            onMouseMove={(e) => {
                                if (editingName) {
                                    e.stopPropagation();
                                }
                                // Only cancel the mouse down if they moved significantly
                                if (dragging?.hasMovedEnough) {
                                    setEditMouseDown(false);
                                }
                            }}
                            className={classnames({
                                'map-element-developer-name': true,
                                'map-element-no-actions':
                                    elementType === MAP_ELEMENT_TYPES.swimlane,
                                'color-white': isColorFillMode(
                                    canvasSettings.mapElementColorStyle,
                                    zoomLevel,
                                ),
                            })}
                            title={path(['current', 'textContent'], nameRef)}
                            contentEditable={isSelected && editingName}
                        >
                            {initialDeveloperName}
                        </span>
                    </span>
                </span>
            </foreignObject>
            {/* Resize handles for scaling the group */}
            {resizeHandles}
            {/* Delete button */}
            {isSelected && !isDragging && (
                <foreignObject {...styles.iconRow} x={width - styles.iconRow.width + 10}>
                    <XCircle
                        size={10}
                        weight="fill"
                        onClick={onDelete}
                        onMouseUp={(e) => executeIfNotDragging(e, dragging)}
                        className="group-element-icon group-element-delete"
                        alt="Delete"
                    />
                    {elementType === MAP_ELEMENT_TYPES.group && (
                        <UngroupIcon
                            onClick={onUngroup}
                            onMouseUp={(e: MouseEvent) => executeIfNotDragging(e, dragging)}
                            className="group-element-icon group-element-unlink"
                            title="Ungroup"
                        />
                    )}
                    <AutoArrangeIcon
                        onClick={onAutoArrangeGroup}
                        onMouseUp={(e: MouseEvent) => executeIfNotDragging(e, dragging)}
                        className="group-element-icon group-element-arrange"
                        alt="Auto Arrange"
                    />
                </foreignObject>
            )}
        </g>
    );
};

export default connect(
    ({ graphEditor }: { graphEditor: { dragging: Dragging } }) => ({
        dragging: graphEditor.dragging,
    }),
    {
        addNotification: addNotificationAction,
    },
)(GroupElementBase);
