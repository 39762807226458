import { useState } from 'react';
import translations from '../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import DataActions from '../common/dataActions/DataActions';
import ListenerList from '../../../../../ts/components/flow/elementConfigurations/common/listeners/ListenerList';
import NameInput from '../../../../../ts/components/flow/elementConfigurations/common/NameInput';
import OutcomeList from '../../../../../ts/components/flow/elementConfigurations/common/outcomes/OutcomeList';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';
import Footer from '../../../../../ts/components/flow/elementConfigurations/common/Footer';

const LoadConfiguration = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const { mapElement, onUpdateName, onUpdateComments, onSaveMapElement, onClose } =
        useMapElement();

    const { developerName, developerSummary } = mapElement;

    const isLoadElementNameValid = !isNullOrEmpty(developerName);

    const onSave = () => {
        setHasSubmitted(true);
        if (isLoadElementNameValid) {
            onSaveMapElement(mapElement);
        }
    };

    return (
        <>
            <ModalBody>
                <NameInput
                    isValid={isLoadElementNameValid}
                    showValidation={hasSubmitted}
                    id="load-element-name"
                    name={mapElement.developerName}
                    onUpdateName={onUpdateName}
                />
                <DataActions />
                <OutcomeList />
                <ListenerList />
                <FormGroup
                    htmlFor="load-element-comments"
                    label="Comments about this Database Load"
                >
                    <textarea
                        id="load-element-comments"
                        className="form-control form-textarea"
                        value={developerSummary ?? ''}
                        onChange={(e) => onUpdateComments(e.target.value)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Footer
                    save={onSave}
                    saveButtonText={translations.GRAPH_config_panel_save}
                    cancel={onClose}
                />
            </ModalFooter>
        </>
    );
};

export default LoadConfiguration;
