import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const selectedFlow = (state = {}, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_SELECTED_FLOW:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default selectedFlow;
