import { memo } from 'react';
import {
    Flag,
    FlowArrow,
    Browser,
    Layout,
    Browsers,
    MathOperations,
    EnvelopeSimple,
    DownloadSimple,
    FloppyDiskBack,
    Trash,
    IdentificationBadge,
    KeyReturn,
    Note,
    Question,
    Clock,
    ArrowFatLeft,
    ArrowFatRight,
    ArrowFatUp,
    ArrowFatDown,
    PlugsConnected,
} from '@phosphor-icons/react';
import SubflowIcon from '../../icons/SubflowIcon';
import GroupIcon from '../../icons/GroupIcon';
import DecisionIcon from '../../icons/DecisionIcon';
import { icon as ProcessIcon } from '../../../../js/components/graph/icons/process';

export type IconName =
    | 'Flag'
    | 'Layout'
    | 'Browser'
    | 'Browsers'
    | 'FlowArrow'
    | 'MathOperations'
    | 'EnvelopeSimple'
    | 'DownloadSimple'
    | 'FloppyDiskBack'
    | 'Trash'
    | 'IdentificationBadge'
    | 'KeyReturn'
    | 'Note'
    | 'Question'
    | 'Clock'
    | 'ArrowFatLeft'
    | 'ArrowFatRight'
    | 'ArrowFatUp'
    | 'ArrowFatDown'
    | 'SubflowIcon'
    | 'GroupIcon'
    | 'DecisionIcon'
    | 'ProcessIcon'
    | 'OpenApiIcon';

interface Props {
    name: IconName;
    size: number;
}

const Icon = ({ name, size }: Props) => {
    switch (name) {
        case 'Flag':
            return <Flag size={size} />;
        case 'Layout':
            return <Layout size={size} />;
        case 'Browser':
            return <Browser size={size} />;
        case 'Browsers':
            return <Browsers size={size} />;
        case 'FlowArrow':
            return <FlowArrow size={size} />;
        case 'MathOperations':
            return <MathOperations size={size} />;
        case 'EnvelopeSimple':
            return <EnvelopeSimple size={size} />;
        case 'DownloadSimple':
            return <DownloadSimple size={size} />;
        case 'FloppyDiskBack':
            return <FloppyDiskBack size={size} />;
        case 'Trash':
            return <Trash size={size} />;
        case 'IdentificationBadge':
            return <IdentificationBadge size={size} />;
        case 'KeyReturn':
            return <KeyReturn size={size} />;
        case 'Note':
            return <Note size={size} />;
        case 'Question':
            return <Question size={size} />;
        case 'Clock':
            return <Clock size={size} />;
        case 'ArrowFatLeft':
            return <ArrowFatLeft size={size} />;
        case 'ArrowFatRight':
            return <ArrowFatRight size={size} />;
        case 'ArrowFatUp':
            return <ArrowFatUp size={size} />;
        case 'ArrowFatDown':
            return <ArrowFatDown size={size} />;
        case 'SubflowIcon':
            return <SubflowIcon size={`${size}`} />;
        case 'GroupIcon':
            return <GroupIcon size={`${size}`} />;
        case 'DecisionIcon':
            return <DecisionIcon size={`${size}`} />;
        case 'ProcessIcon':
            return ProcessIcon;
        case 'OpenApiIcon':
            return <PlugsConnected size={`${size}`} />;
        default:
            return <Question size={size} />;
    }
};

export default memo(Icon);
