import { useRef, useState } from 'react';
import { uploadFile } from './ajax';
import { getLocale } from './date';

export const getSizeDisplay = (bytesCount: number) => {
    const multiplier = 1000;

    const b = { name: 'byte', multiplier: 1 };
    const kb = { name: 'kilobyte', multiplier };
    const mb = { name: 'megabyte', multiplier: multiplier ** 2 };
    const gb = { name: 'gigabyte', multiplier: multiplier ** 3 };

    const unit =
        bytesCount > gb.multiplier
            ? gb
            : bytesCount > mb.multiplier
              ? mb
              : bytesCount > kb.multiplier
                ? kb
                : b;

    const sizeInUnits = bytesCount === 0 ? 0 : bytesCount / unit.multiplier;

    return new Intl.NumberFormat(getLocale(), {
        style: 'unit',
        maximumFractionDigits: 1,
        unitDisplay: 'narrow',
        unit: unit.name,
    }).format(sizeInUnits);
};

export const getProgressBytesDisplay = (totalBytesCount: number, progressBytesCount: number) => {
    return `${getSizeDisplay(progressBytesCount)} of ${getSizeDisplay(totalBytesCount)}`;
};

export const getProgressPercentageDisplay = (percentage: number) => {
    return new Intl.NumberFormat(getLocale(), {
        style: 'unit',
        maximumFractionDigits: 0,
        unitDisplay: 'narrow',
        unit: 'percent',
    }).format(percentage);
};

export const getFileExtension = (filename: string) => {
    return filename.slice((Math.max(0, filename.lastIndexOf('.')) || Number.POSITIVE_INFINITY) + 1);
};

export const useUpload = ({
    tenantId,
    url,
    onError,
    onProgress,
    onComplete,
    sendAsJSON = false,
}: {
    tenantId: string;
    url: string;
    onError?: (error: ProgressEvent<XMLHttpRequestEventTarget> | Event) => void;
    onComplete?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    onProgress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
    sendAsJSON: boolean;
}) => {
    const xhrRef = useRef<XMLHttpRequest>();
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [totalBytes, setTotalBytes] = useState(0);
    const [bytesLoaded, setBytesLoaded] = useState(0);
    const percentage =
        totalBytes === 0 ? 0 : Math.min(100, Math.round((bytesLoaded * 100) / totalBytes));

    const abort = () => {
        xhrRef.current?.abort();
    };

    const reset = () => {
        setIsUploading(false);
        setBytesLoaded(0);
        setTotalBytes(0);
        setFile(null);
        abort();
    };

    const onProgressTapped = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        const { lengthComputable, loaded } = event;
        onProgress?.(event);
        if (lengthComputable) {
            setBytesLoaded(loaded);
        }
    };

    const onCompleteHandler = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        const xhr = xhrRef.current;
        if (xhr && xhr.status >= 200 && xhr.status < 400) {
            onComplete?.(event);
        } else {
            reset();
        }
    };

    const beginUpload = () => {
        if (file === null) {
            return;
        }

        xhrRef.current = uploadFile({
            file,
            tenantId,
            url,
            progressHandler: onProgressTapped,
            completeHandler: onCompleteHandler,
            errorHandler: onError,
            sendAsJSON,
        });

        setIsUploading(true);
    };

    const setFileReset = (file: File | null) => {
        reset();
        setTotalBytes(file?.size ?? 0);
        setFile(file);
    };

    return {
        setFile: setFileReset,
        beginUpload,
        abort,
        file,
        isUploading,
        progressBytes: bytesLoaded,
        progressPercentage: percentage,
    };
};
