import type { MouseEvent } from 'react';
import { mapFriendlyNameToDisplay } from '../../../../js/utils/DisplayValueMappings';

type Props = {
    activeTab: string;
    changeActiveTab: (e: MouseEvent<HTMLButtonElement>, activeTab: string) => void;
    dependencyName: string;
};

const SharedElementTab = ({ activeTab, changeActiveTab, dependencyName }: Props) => {
    return (
        <li className={activeTab === dependencyName ? 'active' : ''}>
            <button
                className="nav-tab"
                onClick={(e) => changeActiveTab(e, dependencyName)}
                title={`${mapFriendlyNameToDisplay(dependencyName)}s`}
                type="button"
            >
                {`${mapFriendlyNameToDisplay(dependencyName)}s`}
            </button>
        </li>
    );
};

export default SharedElementTab;
