import type { ChangeEvent } from 'react';
import { safeToLower } from '../../../../utils';
import FormGroup from '../../../generic/FormGroup';
import { RUNTIME_LABELS } from '../../../page-editor/constants';
import { NAVIGATION_POSITION } from '../../constants';
import type { NavigationElement } from '../utils';

type Props = {
    navigationElement: NavigationElement;
    setNavigationElement: (navigationElement: NavigationElement) => void;
};

const ConfigurePosition = ({ navigationElement, setNavigationElement }: Props) => {
    const handlePositionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setNavigationElement({ ...navigationElement, position: e.target.value });
    };

    return (
        <div className="state-wrapper">
            <h3>Position</h3>
            <FormGroup label="Position of the navigation relative to the page" htmlFor="position">
                <select
                    id="position"
                    className="form-control auto-width"
                    value={safeToLower(navigationElement.position)}
                    onChange={handlePositionChange}
                    required={true}
                >
                    <option key="top" value={NAVIGATION_POSITION.top}>
                        Top
                    </option>
                    <option key="left" value={NAVIGATION_POSITION.left}>
                        Left
                    </option>
                </select>
                <span className="help-block">
                    This property only works with the {RUNTIME_LABELS['NEXT']?.toLowerCase()}{' '}
                    runtime
                </span>
            </FormGroup>
        </div>
    );
};

export default ConfigurePosition;
