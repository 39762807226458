import { useState, useEffect } from 'react';
import Table from '../../../../ts/components/generic/Table';
import { useEnvironments } from '../../../../ts/components/admin/environments/EnvironmentsProvider';
import Modal from '../../../../ts/components/generic/modal/GenericModal';
import translations from '../../../../ts/translations';
import FormGroup from '../../../../ts/components/generic/FormGroup';
import ButtonIcon from '../../../../ts/components/buttons/ButtonIcon';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import { ENVIRONMENT_CATEGORY_TYPES } from '../../../../ts/components/admin/environments/constants';
import { RUNTIME_URI } from '../../../../ts/constants';
import CopyableText from '../../../../ts/components/generic/CopyableText';
import FlowIcon from '../../../../ts/components/icons/FlowIcon';
import { PaintBrush } from '@phosphor-icons/react';
import { getActivatedForEnvironment } from '../../../../ts/sources/flow';
import { useAuth } from '../../../../ts/components/AuthProvider';
import ClipboardInput from '../../../../ts/components/inputs/ClipboardInput';
import { isNullOrEmpty, isNullOrUndefined } from '../../../../ts/utils/guard';
import { stringReplace } from '../../../../ts/utils/string';

const Release = ({
    release,
    reload,
    environmentId,
    showRollback,
    passedEnvironments,
    modalContainerRef,
}) => {
    const [show, toggle] = useState(false);
    const [loadedRelease, setRelease] = useState(null);
    const [showDeployModal, setShowDeployModal] = useState(false);
    const [environment, setCurrentEnvironment] = useState(null);
    const [environmentToDeploy, setEnvironmentToDeploy] = useState(null);
    const [deployed, setDeployed] = useState(false);
    const [rolledBack, setRolledBack] = useState(false);
    const [deployButtonHidden, setDeployButtonHidden] = useState(false);
    const [showRollbackModal, setShowRollbackModal] = useState(false);
    const [removeItemModalVisible, setRemoveItemModalVisible] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);
    const [allowedToDelete, setAllowedToDelete] = useState(false);
    const [showDeleteReleaseModal, setShowDeleteReleaseModal] = useState(false);
    const [defaultPlayer, setDefaultPlayer] = useState(false);
    const [defaultTheme, setDefaultTheme] = useState(false);
    const [flows, setFlows] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState('');
    const [selectedFlow, setSelectedFlow] = useState('');
    const [showRunThemeModal, setShowRunThemeModal] = useState(false);

    const {
        releaseLoading,
        getRelease,
        deploy,
        rollback,
        environments,
        removeFlowFromRelease,
        removeThemeFromRelease,
        deleteRelease,
        tenantId,
        getAllEnvironments,
    } = useEnvironments();

    const { tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const currentEnvironment = Object.values(passedEnvironments ?? environments).find(
            (env) => env.id === release?.currentEnvironmentId,
        );
        setCurrentEnvironment(currentEnvironment);
        setDefaultPlayer(currentEnvironment?.defaultPlayerName);
        setDefaultTheme(currentEnvironment?.defaultThemeName);
    }, []);

    const toggleView = async () => {
        const visible = !show;
        toggle(visible);

        if (visible) {
            const rel = await getRelease(release.id);
            setRelease(rel);

            const currentEnvironment = Object.values(passedEnvironments ?? environments).find(
                (env) => env.id === rel?.currentEnvironmentId,
            );
            setCurrentEnvironment(currentEnvironment);

            if (
                !currentEnvironment ||
                currentEnvironment.classificationType === ENVIRONMENT_CATEGORY_TYPES.development
            ) {
                setAllowedToDelete(true);
            }

            const nextEnvironment = Object.values(passedEnvironments ?? environments).find(
                (environment) => environment.id === currentEnvironment?.nextEnvironmentId,
            );

            if (
                (environmentId && environmentId === currentEnvironment.id && nextEnvironment) ||
                (isNullOrEmpty(environmentId) && nextEnvironment)
            ) {
                setDeployButtonHidden(false);
                setEnvironmentToDeploy(nextEnvironment);
            } else {
                setDeployButtonHidden(true);
            }
        }
    };

    const removeItem = async () => {
        if (isNullOrUndefined(itemToRemove.themeId)) {
            await removeFlowFromRelease(release.id, itemToRemove.flowId);
        } else {
            await removeThemeFromRelease(release.id, itemToRemove.themeId, itemToRemove.name);
        }

        await reload();
    };

    const onDeleteRelease = async () => {
        await deleteRelease(release.id, release.id);
        reload();
    };

    async function reloadWithEnvironments() {
        await getAllEnvironments();
        await reload();
    }

    async function deployRelease() {
        if (environmentToDeploy && (await deploy(loadedRelease.id, environmentToDeploy.id))) {
            setDeployed(true);
        }
    }

    async function rollbackRelease() {
        if (
            await rollback(
                loadedRelease.id,
                isNullOrEmpty(environmentId) ? environment.id : environmentId,
            )
        ) {
            setRolledBack(true);
        }
    }

    function getFlowUrl(flowId) {
        let url = '';
        if (!tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/${defaultPlayer}?flow-id=${flowId}&environment-id=${environment?.id}`;
        } else if (tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/theme/${defaultTheme}?flow-id=${flowId}&environment-id=${environment?.id}`;
        }
        return url;
    }

    async function openPreviewThemeModal(theme) {
        setSelectedTheme(theme);
        setShowRunThemeModal(!showRunThemeModal);

        setFlows(await getActivatedForEnvironment(tenantId, environment?.id));
    }

    function getThemeUrl() {
        const flow = flows.find((x) => x.developerName === selectedFlow);
        if (isNullOrEmpty(flow)) {
            return '';
        }
        return `${RUNTIME_URI}/${tenantId}/play/theme/${selectedTheme}?flow-id=${flow.id.id}&environment-id=${environment?.id}`;
    }

    function runTheme() {
        const url = getThemeUrl();
        window.open(url, '_blank');
        setSelectedFlow('');
        setShowRunThemeModal(!showRunThemeModal);
    }

    const onSelectedFlowChange = ({ target: { value } }) => {
        setSelectedFlow(value);
    };

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <ButtonIcon
                    className="margin-left-small danger"
                    glyph="Delete"
                    aria-label={`Remove ${item.name}`}
                    onClick={() => {
                        setItemToRemove(item);
                        setRemoveItemModalVisible(true);
                    }}
                    title={`Remove ${item.name}`}
                    hidden={deployButtonHidden}
                    iconClass="icon-medium"
                />
            ),
            size: '80px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) => {
                if (!isNullOrUndefined(item.flowId)) {
                    return <FlowIcon width="20" height="20" />;
                }
                if (!isNullOrUndefined(item.themeId)) {
                    return <PaintBrush width="20" height="20" />;
                }
                return <></>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_version_id,
            renderCell: ({ item }) => (
                <CopyableText copyableText={item.versionId} hasCopyButton={true} />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_deployment_notes,
            renderCell: ({ item }) => item.summary,
            size: '300px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_modified,
            renderCell: ({ item }) => {
                return new Date(item.updatedAt).toLocaleString();
            },
        },
        {
            renderHeader: () => translations.ENVIRONMENT_releases_table_run_preview_header,
            renderCell: ({ item }) => {
                //Run a flow
                if (isNullOrEmpty(item.themeId)) {
                    const flowUrl = getFlowUrl(item.flowId);
                    return (
                        <a href={flowUrl} target="_blank" rel="noopener noreferrer">
                            {translations.ENVIRONMENT_run_in + environment?.name}
                        </a>
                    );
                }
                //Preview a Theme
                return (
                    <button
                        type="button"
                        className="btn btn-primary outcome"
                        onClick={() => openPreviewThemeModal(item.name)}
                    >
                        {translations.ENVIRONMENT_preview_in + environment?.name}
                    </button>
                );
            },
        },
    ];

    let content;
    if (loadedRelease) {
        let rows = loadedRelease.flows;
        if (!isNullOrUndefined(loadedRelease.themes)) {
            if (isNullOrUndefined(loadedRelease.flows)) {
                rows = loadedRelease.themes;
            } else {
                rows = rows.concat(loadedRelease.themes);
            }
        }
        content = (
            <>
                <ConfirmModal
                    show={removeItemModalVisible}
                    title={translations.ENVIRONMENT_remove_item_confirmation_title}
                    messages={[
                        stringReplace(translations.ENVIRONMENT_remove_item_confirmation_message, {
                            itemName: itemToRemove?.name ?? '',
                            releaseName: release?.name ?? '',
                        }),
                    ]}
                    buttonStyle="danger"
                    buttonCaption="Remove"
                    onCancel={() => setRemoveItemModalVisible(false)}
                    onConfirm={() => {
                        removeItem();
                        setRemoveItemModalVisible(false);
                    }}
                    container={modalContainerRef.current}
                />

                <ConfirmModal
                    show={showDeleteReleaseModal}
                    title={translations.ENVIRONMENT_delete_release}
                    messages={[
                        stringReplace(
                            translations.ENVIRONMENT_delete_release_confirmation_message,
                            {
                                releaseName: release?.name ?? '',
                            },
                        ),
                    ]}
                    buttonStyle="danger"
                    buttonCaption="Delete"
                    onCancel={() => setShowDeleteReleaseModal(false)}
                    onConfirm={() => {
                        onDeleteRelease();
                        setShowDeleteReleaseModal(false);
                    }}
                    container={modalContainerRef.current}
                />
                <Table columns={columns} items={rows} isLoading={releaseLoading} />
                {!deployButtonHidden && environmentToDeploy ? (
                    <button
                        type="button"
                        className="btn btn-primary outcome margin-right"
                        onClick={() => setShowDeployModal(!showDeployModal)}
                    >
                        {translations.ENVIRONMENT_promote + environmentToDeploy.name}
                    </button>
                ) : null}
                {showRollback ? (
                    <button
                        type="button"
                        className="btn btn-default outcome"
                        onClick={() => setShowRollbackModal(!showRollbackModal)}
                    >
                        {translations.ENVIRONMENT_rollback}
                    </button>
                ) : null}
                {allowedToDelete ? (
                    <button
                        type="button"
                        className="btn btn-default outcome"
                        onClick={() => setShowDeleteReleaseModal(!showRollbackModal)}
                    >
                        {translations.ENVIRONMENT_delete_release}
                    </button>
                ) : null}
                <Modal
                    show={showDeployModal}
                    container={modalContainerRef.current}
                    onHide={() => setShowDeployModal(!showDeployModal)}
                    title={
                        deployed
                            ? translations.ENVIRONMENT_promote_release_success
                            : translations.ENVIRONMENT_promote_release
                    }
                    className="config-modal"
                    renderBody={() =>
                        deployed ? (
                            <p>
                                {[
                                    stringReplace(
                                        translations.ENVIRONMENT_promote_release_message,
                                        {
                                            releaseName: release?.name ?? '',
                                            environmentName: environmentToDeploy?.name ?? '',
                                        },
                                    ),
                                ]}
                            </p>
                        ) : (
                            <>
                                <p>
                                    {[
                                        stringReplace(translations.ENVIRONMENT_promote_subtext, {
                                            releaseName: release?.name ?? '',
                                        }),
                                    ]}
                                </p>
                                <FormGroup
                                    label={translations.ENVIRONMENT_promote_target_environment}
                                    htmlFor="deployment-environment"
                                >
                                    <input
                                        id="deployment-environment"
                                        className="form-control form-control-dynamic"
                                        value={environmentToDeploy ? environmentToDeploy.name : ''}
                                        type="text"
                                        readOnly
                                    />
                                </FormGroup>
                            </>
                        )
                    }
                    renderFooter={() =>
                        !deployed && environmentToDeploy ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-default outcome"
                                    onClick={() => setShowDeployModal(!showDeployModal)}
                                >
                                    {translations.ENVIRONMENT_cancel}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary outcome"
                                    onClick={() => deployRelease()}
                                >
                                    {translations.ENVIRONMENT_promote + environmentToDeploy.name}
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary outcome"
                                onClick={() => reloadWithEnvironments()}
                            >
                                {translations.ENVIRONMENT_finish}
                            </button>
                        )
                    }
                />
                <Modal
                    show={showRollbackModal}
                    onHide={() => setShowRollbackModal(!showRollbackModal)}
                    container={modalContainerRef.current}
                    title={translations.ENVIRONMENT_rollback_release}
                    bodyClassName="modal-body-no-height"
                    renderBody={() => (
                        <p>
                            {rolledBack
                                ? `${loadedRelease.name} was successfully rolled back from ${environment.name}`
                                : translations.ENVIRONMENT_rollback_release_confirmation +
                                  release.name}
                        </p>
                    )}
                    className="config-modal"
                    renderFooter={() =>
                        rolledBack ? (
                            <button
                                type="button"
                                className="btn btn-primary outcome"
                                onClick={() => reloadWithEnvironments()}
                            >
                                {translations.ENVIRONMENT_finish}
                            </button>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-default outcome"
                                    onClick={() => setShowRollbackModal(!showRollbackModal)}
                                >
                                    {translations.ENVIRONMENT_cancel}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-danger outcome"
                                    onClick={() => rollbackRelease()}
                                >
                                    {translations.ENVIRONMENT_rollback}
                                </button>
                            </>
                        )
                    }
                />
                <Modal
                    show={showRunThemeModal}
                    onHide={() => setShowRunThemeModal(!showRunThemeModal)}
                    container={modalContainerRef.current}
                    title={translations.ENVIRONMENT_run}
                    bodyClassName="modal-body-no-height"
                    renderBody={() => (
                        <>
                            <FormGroup
                                label="Which Flow do you want to apply the theme to?"
                                htmlFor="run-type-select"
                                isRequired={true}
                            >
                                <select
                                    id="run-type-select"
                                    value={selectedFlow}
                                    onChange={onSelectedFlowChange}
                                    placeholder="Please Select"
                                    required
                                    className="form-control"
                                >
                                    <option value="" key="please-select">
                                        {translations.ENVIRONMENT_select_flow}
                                    </option>
                                    {flows.map((flows) => (
                                        <option
                                            key={flows.developerName}
                                            value={flows.developerName}
                                        >
                                            {flows.developerName}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                            <FormGroup label="Url" className="margin-top">
                                <ClipboardInput value={getThemeUrl()} buttonTitle="Copy URL" />
                            </FormGroup>
                        </>
                    )}
                    className="config-modal"
                    renderFooter={() => (
                        <>
                            <button
                                type="button"
                                className="btn btn-default outcome"
                                onClick={() => setShowRunThemeModal(!showRunThemeModal)}
                            >
                                {translations.ENVIRONMENT_cancel}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary outcome"
                                onClick={() => runTheme()}
                                disabled={isNullOrEmpty(selectedFlow)}
                            >
                                {translations.ENVIRONMENT_run}
                            </button>
                        </>
                    )}
                />
            </>
        );
    }

    return (
        <div className="expandable-area margin-bottom margin-right-sml">
            <div
                className="expandable-header margin-left"
                onClick={toggleView}
                onKeyPress={toggleView}
                // biome-ignore lint/a11y/useSemanticElements: Requires refactor
                role="button"
                tabIndex={0}
            >
                <div className="inline-block">
                    <h5 className="inline-block margin-right">{`${release.name}  `}</h5>
                    {`Last deployed: ${new Date(release.lastDeployed).toLocaleString()} to: ${
                        environment?.name ?? ENVIRONMENT_CATEGORY_TYPES.development
                    }`}
                </div>
                <span
                    className={
                        show
                            ? 'float-right padding padding-top glyphicon glyphicon-triangle-bottom rotate180'
                            : 'float-right padding padding-top glyphicon glyphicon-triangle-bottom'
                    }
                    aria-hidden="true"
                />
            </div>
            <div className={show ? 'expand-show padding-default' : 'expand-hide'}>{content}</div>
        </div>
    );
};

export default Release;
