import translations from '../../../../translations';

export const FILTER_TYPES = {
    noFilter: { label: 'Do not filter the data', value: 'NA' },
    filterById: { label: 'Get the data based on a unique identifier', value: 'BY_ID' },
    filterByWhereClause: { label: 'Get the data based on a where filter', value: 'BY_WHERE' },
};

export const CRUD_OPERATION_TYPES = {
    load: 'LOAD',
    save: 'SAVE',
    delete: 'DELETE',
};

export const FRIENDLY_SCREEN_NAMES = {
    page: 'Page',
    decision: 'Decision',
    delete: 'Database Delete',
    message: 'Message',
    save: 'Database Save',
    load: 'Database Load',
};

export const DATA_ACTION_VALUE_SELECTION_LABEL = {
    LOAD: translations.DATA_ACTION_load_value_label,
    SAVE: translations.DATA_ACTION_value_to_save_label,
    DELETE: translations.DATA_ACTION_value_to_delete_label,
};

export const DATA_ACTION_TYPE_BINDING_LABEL = {
    LOAD: translations.DATA_ACTION_load_binding_label,
    SAVE: translations.DATA_ACTION_save_binding_label,
    DELETE: translations.DATA_ACTION_delete_binding_label,
};
