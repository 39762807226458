import translations from '../../../../../ts/translations';

import { useState, createContext, useContext } from 'react';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { getType } from '../../../../../ts/sources/type';
import { FILTER_TYPES } from '../../../../../ts/components/flow/elementConfigurations/common/constants';

const DATA_ACTION_SCREENS = {
    detail: { key: 'dataAction', label: 'Data Action' },
    filter: { key: 'filter', label: 'Filter' },
    criteria: { key: 'criteria', label: 'Criteria' },
};

const Context = createContext(undefined);

const DataActionProvider = ({ defaultScreen, crudOperationType, children }) => {
    const {
        mapElement,
        onPushBreadCrumb,
        setMapElement,
        onSwitchScreen,
        onSetActiveBreadCrumb,
        notifyError,
    } = useMapElement();

    const initialDataAction = {
        crudOperationType,
        developerName: null,
        disabled: false,
        isSmartSave: false,
        objectDataRequest: null,
        order: 0,
        valueElementToApplyId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
        valueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
    };

    const initialCriteria = {
        columnTypeElementPropertyDeveloperName: null,
        columnTypeElementPropertyId: null,
        criteriaType: null,
        criteriaTypeFriendly: null,
        valueElementToReferenceDeveloperName: null,
        valueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
    };

    const [dataActionToEdit, setDataActionToEdit] = useState({
        dataAction: null,
        index: null,
    });
    const [filterToEdit, setFilterToEdit] = useState(null);
    const [criteriaToEdit, setCriteriaToEdit] = useState({
        criteria: null,
        index: null,
    });

    const onCreateDataAction = () => {
        const totalDataActions = mapElement.dataActions.length;
        setDataActionToEdit({
            dataAction: { ...initialDataAction, order: totalDataActions },
            index: totalDataActions + 1,
        });
        onSwitchScreen(DATA_ACTION_SCREENS.detail.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.detail.key, DATA_ACTION_SCREENS.detail.label);
    };

    const onEditDataAction = (dataAction, index) => {
        setDataActionToEdit({ dataAction, index });
        onSwitchScreen(DATA_ACTION_SCREENS.detail.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.detail.key, DATA_ACTION_SCREENS.detail.label);
    };

    const onDeleteDataAction = (index) => {
        setMapElement({
            ...mapElement,
            dataActions: mapElement.dataActions.filter((_, i) => i !== index),
        });
    };

    const checkIsServiceType = async (id) => {
        try {
            const type = await getType(id);
            return !!type.serviceElementId;
        } catch ({ message }) {
            notifyError(message);
        }
    };

    const onSetDataActionLoadValue = async (value) => {
        const isServiceType = value?.typeElementId
            ? await checkIsServiceType(value.typeElementId)
            : false;
        if (!value || (value && isServiceType)) {
            const dataAction = {
                ...dataActionToEdit.dataAction,
                objectDataRequest: {
                    ...dataActionToEdit.dataAction.objectDataRequest,
                    typeElementId: value ? value.typeElementId : null,
                    typeElementBindingId: null,
                },
                valueElementToApplyId: {
                    ...dataActionToEdit.dataAction.valueElementToApplyId,
                    id: value ? value.id : null,
                },
                valueElementToReferenceId: {
                    ...dataActionToEdit.dataAction.valueElementToReferenceId,
                    id: value ? value.id : null,
                },
            };
            setDataActionToEdit({ ...dataActionToEdit, dataAction });
        } else {
            notifyError(translations.DATA_ACTION_value_with_no_connector_error);
        }
    };

    const onUpdateDataActionName = (developerName) => {
        setDataActionToEdit({
            ...dataActionToEdit,
            dataAction: { ...dataActionToEdit.dataAction, developerName },
        });
    };

    const onUpdateDataActionOrder = (order) => {
        setDataActionToEdit({
            ...dataActionToEdit,
            dataAction: { ...dataActionToEdit.dataAction, order },
        });
    };

    const onUpdateDataActionDisabled = ({ isOn }) => {
        setDataActionToEdit({
            ...dataActionToEdit,
            dataAction: { ...dataActionToEdit.dataAction, disabled: isOn },
        });
    };

    const onUpdateDataActionSmartSave = ({ isOn }) => {
        setDataActionToEdit({
            ...dataActionToEdit,
            dataAction: { ...dataActionToEdit.dataAction, isSmartSave: isOn },
        });
    };

    const onUpdateDataActionBinding = (bindingId) => {
        const dataAction = {
            ...dataActionToEdit.dataAction,
            objectDataRequest: {
                ...dataActionToEdit.dataAction.objectDataRequest,
                typeElementBindingId: bindingId,
            },
        };
        setDataActionToEdit({ ...dataActionToEdit, dataAction });
    };

    const onApplyDataAction = (index) => {
        const dataActionExists = mapElement.dataActions
            ? mapElement.dataActions.find((_, i) => i === index)
            : null;
        const dataActions = dataActionExists
            ? mapElement.dataActions.map((existingDataAction, i) =>
                  i === index ? dataActionToEdit.dataAction : existingDataAction,
              )
            : [...mapElement.dataActions, dataActionToEdit.dataAction];

        setMapElement({ ...mapElement, dataActions });
        onSwitchScreen(defaultScreen);
    };

    const onCreateDataActionFilter = () => {
        setFilterToEdit(null);
        onSwitchScreen(DATA_ACTION_SCREENS.filter.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.filter.key, DATA_ACTION_SCREENS.filter.label);
    };

    const onEditDataActionFilter = () => {
        setFilterToEdit(dataActionToEdit.dataAction.objectDataRequest.listFilter);
        onSwitchScreen(DATA_ACTION_SCREENS.filter.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.filter.key, DATA_ACTION_SCREENS.filter.label);
    };

    const onSetFilterComparisonType = (comparisonType) => {
        setFilterToEdit({
            ...filterToEdit,
            comparisonType,
        });
    };

    const onSetFilterOrderDirectionType = (orderByDirectionType) => {
        setFilterToEdit({
            ...filterToEdit,
            orderByDirectionType,
        });
    };

    const onSetFilterRecordLimit = (limit) => {
        setFilterToEdit({
            ...filterToEdit,
            limit,
        });
    };

    const onSetFilterOrderByTypePropertyId = (orderByTypeElementPropertyId) => {
        setFilterToEdit({
            ...filterToEdit,
            orderByTypeElementPropertyId,
        });
    };

    const onSetFilterId = (value) => {
        setFilterToEdit({
            ...filterToEdit,
            filterId: {
                command: null,
                id: value ? value.id : null,
                typeElementPropertyId: value ? value.typeElementPropertyId : null,
            },
        });
    };

    const onSetFilterType = (filterType) => {
        let updatedFilter = null;

        if (filterType === FILTER_TYPES.filterById.value) {
            updatedFilter = {
                ...filterToEdit,
                filterId: {},
                where: null,
                comparisonType: null,
                orderByDirectionType: null,
                orderByTypeElementPropertyId: null,
            };
        }

        if (filterType === FILTER_TYPES.filterByWhereClause.value) {
            updatedFilter = {
                ...filterToEdit,
                where: [],
                filterId: null,
            };
        }

        setFilterToEdit(updatedFilter);
    };

    const onApplyDataActionFilter = () => {
        setDataActionToEdit({
            ...dataActionToEdit,
            dataAction: {
                ...dataActionToEdit.dataAction,
                objectDataRequest: {
                    ...dataActionToEdit.dataAction.objectDataRequest,
                    listFilter: filterToEdit,
                },
            },
        });
        onSwitchScreen(DATA_ACTION_SCREENS.detail.key);
    };

    const onCreateDataActionFilterCriteria = () => {
        const totalCriterias = filterToEdit.where.length;
        setCriteriaToEdit({
            criteria: { ...initialCriteria },
            index: totalCriterias + 1,
        });
        onSwitchScreen(DATA_ACTION_SCREENS.criteria.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.criteria.key, DATA_ACTION_SCREENS.criteria.label);
    };

    const onEditDataActionFilterCriteria = (criteria, index) => {
        setCriteriaToEdit({ criteria, index });
        onSwitchScreen(DATA_ACTION_SCREENS.criteria.key);
        onPushBreadCrumb(DATA_ACTION_SCREENS.criteria.key, DATA_ACTION_SCREENS.criteria.label);
    };

    const onDeleteDataActionFilterCriteria = (index) => {
        setFilterToEdit({
            ...filterToEdit,
            where: filterToEdit.where.filter((_, i) => i !== index),
        });
    };

    const onSetCriteriaTypePropertyId = (
        columnTypeElementPropertyId,
        columnTypeElementPropertyDeveloperName,
    ) => {
        setCriteriaToEdit({
            ...criteriaToEdit,
            criteria: {
                ...criteriaToEdit.criteria,
                columnTypeElementPropertyId,
                columnTypeElementPropertyDeveloperName,
            },
        });
    };

    const onSetCriteriaType = (criteriaType, criteriaTypeFriendly) => {
        setCriteriaToEdit({
            ...criteriaToEdit,
            criteria: { ...criteriaToEdit.criteria, criteriaType, criteriaTypeFriendly },
        });
    };

    const onSetCriteriaValueElementToReference = (value) => {
        const generateValueDisplayFormat = () => {
            const { developerName, typeElementPropertyDeveloperName } = value;
            let valueElementToReferenceDeveloperName = `[${developerName}`;
            if (typeElementPropertyDeveloperName) {
                valueElementToReferenceDeveloperName += ` / ${value.typeElementPropertyDeveloperName}`;
            }
            valueElementToReferenceDeveloperName += ']';
            return valueElementToReferenceDeveloperName;
        };

        setCriteriaToEdit({
            ...criteriaToEdit,
            criteria: {
                ...criteriaToEdit.criteria,
                valueElementToReferenceId: {
                    command: null,
                    id: value ? value.id : null,
                    typeElementPropertyId: value ? value.typeElementPropertyId : null,
                },
                valueElementToReferenceDeveloperName: value ? generateValueDisplayFormat() : null,
            },
        });
    };

    const onApplyCriteria = (index) => {
        const criteriaExists = filterToEdit.where
            ? filterToEdit.where.find((_, i) => i === index)
            : null;

        const criterias = criteriaExists
            ? filterToEdit.where.map((existingCriteria, i) =>
                  i === index ? criteriaToEdit.criteria : existingCriteria,
              )
            : [...(filterToEdit.where || []), criteriaToEdit.criteria];

        setFilterToEdit({
            ...filterToEdit,
            where: criterias,
        });

        onSwitchScreen(DATA_ACTION_SCREENS.filter.key);
    };

    const onReturnToDataActionScreen = () => {
        onSetActiveBreadCrumb(DATA_ACTION_SCREENS.detail.key);
    };

    const onReturnToDataActionFilterScreen = () => {
        onSetActiveBreadCrumb(DATA_ACTION_SCREENS.filter.key);
    };

    const onReturnToDefaultScreen = () => {
        onSetActiveBreadCrumb(defaultScreen);
    };

    const contextValue = {
        dataActionToEdit,
        onCreateDataAction,
        onEditDataAction,
        onDeleteDataAction,
        onSetDataActionLoadValue,
        onUpdateDataActionName,
        onUpdateDataActionBinding,
        onApplyDataAction,
        onCreateDataActionFilter,
        onCreateDataActionFilterCriteria,
        onEditDataActionFilterCriteria,
        onDeleteDataActionFilterCriteria,
        onReturnToDataActionScreen,
        onReturnToDataActionFilterScreen,
        onReturnToDefaultScreen,
        onSetFilterComparisonType,
        onSetFilterOrderDirectionType,
        onSetFilterOrderByTypePropertyId,
        onSetFilterType,
        onApplyCriteria,
        onSetCriteriaTypePropertyId,
        onSetCriteriaType,
        onSetCriteriaValueElementToReference,
        criteriaToEdit,
        onSetFilterId,
        onEditDataActionFilter,
        filterToEdit,
        onApplyDataActionFilter,
        onUpdateDataActionOrder,
        onUpdateDataActionDisabled,
        onUpdateDataActionSmartSave,
        onSetFilterRecordLimit,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useDataAction = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useDataAction must be used within a DataActionProvider');
    }
    return context;
};

export { DataActionProvider, useDataAction };
