import { ExInput, ExButton, ButtonType, ButtonFlavor } from '@boomi/exosphere';
import translations from '../../../translations';
import GenericModal from '../../generic/modal/GenericModal';
import ModalBody from '../../generic/modal/ModalBody';
import ModalFooter from '../../generic/modal/ModalFooter';
import { useState } from 'react';
import { setLatest } from '../../../sources/flow';
import Loader from '../../loader/Loader';

type Props = {
    show: boolean;
    onCancel: () => void;
    onFlowCreated: (flowId: string) => void;
    onError: (error: Error) => void;
};

const NewFlowModal = ({ onCancel, onFlowCreated, show, onError }: Props) => {
    const [flowName, setFlowName] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isValid = flowName.length > 0;

    const resetForm = () => {
        setHasSubmitted(false);
        setFlowName('');
    };

    const handleSave = async () => {
        setHasSubmitted(true);

        if (isValid) {
            setIsLoading(true);
            try {
                const response = await setLatest({
                    id: null,
                    developerName: flowName,
                    developerSummary: '',
                    startMapElementId: null,
                    allowJumping: false,
                    enableHistoricalNavigation: false,
                    stateExpirationLength: 0,
                    idleStateExpirationLength: 0,
                    authorization: {
                        serviceElementId: null,
                        globalAuthenticationType: 'PUBLIC',
                        streamBehaviourType: 'NONE',
                        showPagesAsReadOnly: false,
                        groups: [],
                        users: [],
                        locations: [],
                    },
                });
                onFlowCreated(response.id.id);
                resetForm();
            } catch (e) {
                onError(e as Error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCancel = () => {
        onCancel();
        resetForm();
    };

    return (
        <GenericModal
            show={show}
            onHide={handleCancel}
            title={translations.FLOW_CREATE_NEW}
            className="new-flow-modal modal-dialog-small"
        >
            <ModalBody className="auto-height">
                {isLoading && <Loader />}
                <ExInput
                    data-testid="flow-name-input"
                    label="Name"
                    value={flowName}
                    onInput={(event) => setFlowName((event.target as HTMLInputElement).value)}
                    required={true}
                    invalid={hasSubmitted && !isValid}
                    error-msg="This field is required."
                    type="text"
                    autofocus={true}
                />
            </ModalBody>
            <ModalFooter className="buttons-right">
                <ExButton
                    type={ButtonType.SECONDARY}
                    flavor={ButtonFlavor.BRANDED}
                    onClick={handleCancel}
                    data-testid="create-flow-cancel-button"
                >
                    {translations.COMMON_cancel}
                </ExButton>
                <ExButton
                    type={ButtonType.PRIMARY}
                    flavor={ButtonFlavor.BRANDED}
                    onClick={handleSave}
                    data-testid="create-flow-button"
                >
                    {translations.COMMON_save}
                </ExButton>
            </ModalFooter>
        </GenericModal>
    );
};

export default NewFlowModal;
