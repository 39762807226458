import { getElements } from '../../../ts/config/graphElements';
import { COLOUR_MAPPINGS } from '../graph/elements/elementStyles';
import MapElementIcon from '../../../ts/components/icons/MapElementIcon';
import ComponentWithTooltip from '../../../ts/components/generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from '../../../ts/components/generic/tooltip/TooltipWithHeader';

const getTooltips = (elements) => {
    return new Map(
        elements
            .filter((element) => element.tooltip)
            .map((element) => [
                element.name,
                <TooltipWithHeader
                    key={element.id}
                    header={element.tooltip.title}
                    content={element.tooltip.content}
                />,
            ]),
    );
};

const CanvasPalette = () => {
    const graphElements = getElements();
    const tooltips = getTooltips(graphElements);
    const items = graphElements.map((element) => {
        let itemClasses = 'sidebar-element no-select new-canvas sidebar-btn';
        if (element.isBeta) {
            itemClasses = `${itemClasses} beta`;
        }
        return (
            element.display && (
                <ComponentWithTooltip
                    key={element.name}
                    trigger={['hover', 'focus']}
                    fadeTime={0.1}
                    tooltipContent={tooltips.get(element.name)}
                    tooltipPlacement="right"
                    wrapperClass="sidebar-button-wrapper"
                    tooltipClass="graph-tooltip"
                    tooltipArrowClass="graph-tooltip-arrow"
                    clearance={10}
                    arrowClearance={7}
                >
                    <button
                        id={element.id}
                        data-flow={element.flow}
                        data-name={element.id}
                        data-style={element.style}
                        data-testid="sidebar-element"
                        className={itemClasses}
                        type="button"
                    >
                        <span
                            className="sidebar-btn-icon"
                            style={{ background: COLOUR_MAPPINGS[element.id] }}
                        >
                            <span className="glyphicon">
                                <MapElementIcon elementType={element.id} size={17} />
                            </span>
                        </span>
                        <div className="sidebar-item-text">{element.name}</div>
                    </button>
                </ComponentWithTooltip>
            )
        );
    });

    return <div className="canvas-palette floating-panel">{items}</div>;
};

export default CanvasPalette;
