import ButtonIcon from '../../buttons/ButtonIcon';
import {
    Play,
    FileArrowUp,
    Sliders,
    Wrench,
    CloudArrowUp,
    ClockCounterClockwise,
    ListBullets,
} from '@phosphor-icons/react';
import Run from '../../flow/Run';
import {
    type KeyboardEventHandler,
    type MouseEventHandler,
    type ReactNode,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import Activate from '../../flow/Activate';
import { snapshotFlow } from '../../../sources/build';
import { SYSTEM_ROLES } from '../../../constants';
import { useAuth } from '../../AuthProvider';
import ComponentWithTooltip from '../../generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from '../../generic/tooltip/TooltipWithHeader';
import { RunProvider } from '../../graph/RunProvider';
import type {
    AddNotification,
    FlowGraphMapElementResponseAPI,
    NotifyError,
    NotifySuccess,
} from '../../../types';
import NavigationIcon from '../../icons/NavigationIcon';
import NavigationModal from '../../flow/navigation/NavigationModal';
import Properties from './properties/Properties';
import { getFlag } from '../../../utils/flags';
import { getTenantId } from '../../../utils';
import SharedElementsModal from '../../flow/sharedElements/SharedElementsModal';

interface ActionButtonProps {
    title: string;
    description: string;
    renderIcon: () => ReactNode;
    onClick: MouseEventHandler;
    selected?: boolean;
}

const ActionButton = ({
    title,
    description,
    renderIcon,
    onClick,
    selected = false,
}: ActionButtonProps) => {
    return (
        <ComponentWithTooltip
            trigger={['hover', 'focus']}
            fadeTime={0.1}
            tooltipContent={<TooltipWithHeader header={title} content={description} />}
            tooltipPlacement="bottom"
            wrapperClass="flow-action-wrapper"
            tooltipClass="graph-tooltip"
            tooltipArrowClass="graph-tooltip-arrow"
            clearance={10}
            arrowClearance={7}
        >
            <ButtonIcon
                aria-label={title}
                className={`flow-action${selected ? ' dark' : ''}`}
                onClick={onClick}
            >
                {renderIcon()}
            </ButtonIcon>
        </ComponentWithTooltip>
    );
};

interface ActionMenuProps {
    flowId: string;
    isActive: boolean;
    setTabTitle: (title: string) => void;
    modalContainer: HTMLElement;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    addNotification: AddNotification;
    onModalOpen?: (isOpen: boolean) => void;
    mapElements: FlowGraphMapElementResponseAPI[];
    showHistory: boolean;
    setShowHistory: (open: boolean) => void;
    loadReleases: () => void;
}

const FlowActionMenu = ({
    flowId,
    isActive,
    setTabTitle,
    modalContainer,
    notifyError,
    notifySuccess,
    addNotification,
    onModalOpen,
    mapElements,
    showHistory,
    setShowHistory,
    loadReleases,
}: ActionMenuProps) => {
    const [showNavigationModal, setShowNavigationModal] = useState(false);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const [showRunModal, setShowRunModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showBuildModal, setShowBuildModal] = useState(false);
    const [showDependenciesModal, setShowDependenciesModal] = useState(false);

    const isModalOpen =
        showNavigationModal ||
        showPropertiesModal ||
        showRunModal ||
        showPublishModal ||
        showBuildModal;

    const snapshot = useCallback(
        () =>
            snapshotFlow({ flowId, comment: null })
                .then(() => notifySuccess('Flow states successfully updated'))
                .catch(notifyError),
        [flowId, notifyError, notifySuccess],
    );

    const { tenant } = useAuth();

    const tenantSettings = tenant?.tenantSettings;

    const environmentsIsOn = tenantSettings?.environments ?? true;
    const themesIsOn = tenantSettings?.themes ?? true;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        onModalOpen?.(isModalOpen);
    }, [isModalOpen]);

    useEffect(() => {
        const onKeyDown: KeyboardEventHandler = (event) => {
            if (isActive && !isModalOpen) {
                if (event.ctrlKey && event.key === 'r') {
                    event.preventDefault();
                    setShowRunModal(true);
                }

                if (environmentsIsOn && event.ctrlKey && event.key === 'u') {
                    event.preventDefault();
                    snapshot();
                }
            }
        };

        document.addEventListener(
            'keydown',
            onKeyDown as unknown as EventListenerOrEventListenerObject,
        );
        return () => {
            document.removeEventListener(
                'keydown',
                onKeyDown as unknown as EventListenerOrEventListenerObject,
            );
        };
    }, [environmentsIsOn, isActive, isModalOpen, snapshot]);

    if (tenant === null) {
        return null;
    }

    return (
        <RunProvider>
            <div className="floating-panel flow-action-menu flex">
                <ActionButton
                    title="Dependencies"
                    description="List the dependencies of this Flow"
                    renderIcon={() => <ListBullets size={20} />}
                    onClick={() => setShowDependenciesModal(true)}
                />
                {showDependenciesModal && (
                    <SharedElementsModal
                        tenantId={getTenantId()}
                        flowId={flowId}
                        addNotification={addNotification}
                        close={() => setShowDependenciesModal(false)}
                        container={modalContainer}
                    />
                )}
                <ActionButton
                    title="Navigation"
                    description="Manage navigation bars for this Flow"
                    renderIcon={() => <NavigationIcon />}
                    onClick={() => setShowNavigationModal(true)}
                />
                {showNavigationModal && (
                    <NavigationModal
                        flowId={flowId}
                        addNotification={addNotification}
                        close={() => setShowNavigationModal(false)}
                        container={modalContainer}
                        mapElements={mapElements}
                    />
                )}
                <ActionButton
                    title="Properties"
                    description="Edit the Properties of this Flow i.e. Access Control, etc"
                    renderIcon={() => <Sliders />}
                    onClick={() => setShowPropertiesModal(true)}
                />
                {showPropertiesModal && (
                    <Properties
                        flowId={flowId}
                        close={() => setShowPropertiesModal(false)}
                        container={modalContainer}
                        environmentsIsOn={environmentsIsOn}
                        setTabTitle={setTabTitle}
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                    />
                )}
                {environmentsIsOn && getFlag('HISTRY') && (
                    <ActionButton
                        title="History"
                        description="View the version history to compare the differences, rollback and restore"
                        renderIcon={() => <ClockCounterClockwise />}
                        onClick={() => setShowHistory(!showHistory)}
                        selected={showHistory}
                    />
                )}
                <ActionButton
                    title="Run (Ctrl + R)"
                    description="Build &amp; Run this version of the Flow"
                    renderIcon={() => <Play size={20} />}
                    onClick={() => setShowRunModal(true)}
                />
                {showRunModal && (
                    <Run
                        flowId={flowId}
                        onClose={() => setShowRunModal(false)}
                        container={modalContainer}
                        environmentsEnabled={environmentsIsOn}
                        themesEnabled={themesIsOn}
                        notifyError={notifyError}
                    />
                )}
                {!environmentsIsOn && (
                    <>
                        <ActionButton
                            title="Publish"
                            description="Make this version of the Flow the default version"
                            renderIcon={() => <CloudArrowUp size={20} />}
                            onClick={() => setShowPublishModal(true)}
                        />
                        {showPublishModal && (
                            <Activate
                                flowId={flowId}
                                onClose={() => setShowPublishModal(false)}
                                container={modalContainer}
                                themesEnabled={themesIsOn}
                                tenantId={tenant.id}
                                tenantDeveloperName={tenant.developerName}
                                environmentsEnabled={environmentsIsOn}
                                isAdmin={tenant.role === SYSTEM_ROLES.administrator.developerName}
                                isMCR={!!tenant.organization}
                                addNotification={addNotification}
                                loadReleases={loadReleases}
                            />
                        )}
                    </>
                )}
                {environmentsIsOn && (
                    <>
                        <ActionButton
                            title="Update (Ctrl + U)"
                            description="Update all running Flow states"
                            renderIcon={() => <FileArrowUp size={20} />}
                            onClick={snapshot}
                        />
                        <ActionButton
                            title="Build"
                            description="Build and add this version of the Flow to a release"
                            renderIcon={() => <Wrench size={20} />}
                            onClick={() => setShowBuildModal(true)}
                        />
                        {showBuildModal && (
                            <Activate
                                flowId={flowId}
                                onClose={() => setShowBuildModal(false)}
                                container={modalContainer}
                                themesEnabled={themesIsOn}
                                tenantId={tenant.id}
                                tenantDeveloperName={tenant.developerName}
                                environmentsEnabled={environmentsIsOn}
                                isAdmin={tenant.role === SYSTEM_ROLES.administrator.developerName}
                                isMCR={!!tenant.organization}
                                addNotification={addNotification}
                                loadReleases={loadReleases}
                            />
                        )}
                    </>
                )}
            </div>
        </RunProvider>
    );
};

export default memo(FlowActionMenu);
