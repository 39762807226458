import { useEffect, useState } from 'react';
import { getType } from '../../../../sources/type';
import FormGroup from '../../../generic/FormGroup';
import Loader from '../../../loader/Loader';

import { DATA_ACTION_TYPE_BINDING_LABEL } from './constants';
import type { TypeElementBindingAPI } from '../../../../types';

type Props = {
    typeElementId: string;
    typeElementBindingId: string;
    onSelect: (id: string) => void;
    validationMessage: string;
    isValid: boolean;
    showValidation: boolean;
    crudOperationType: 'LOAD' | 'SAVE' | 'DELETE';
};

const TypeBindingDropdown = ({
    typeElementId,
    typeElementBindingId,
    onSelect,
    validationMessage,
    isValid,
    showValidation,
    crudOperationType,
}: Props) => {
    const [bindings, setBindings] = useState<TypeElementBindingAPI[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchType = async () => {
        const typeResult = await getType(typeElementId);

        if (typeResult.bindings) {
            setBindings(typeResult.bindings || []);

            // On render typeElementBindingId is empty so client side validation thinks nothing is selected.
            // Since the first option is automatically selected it's impossible to get onSelect to call unless another value exists or it's done manually like this.
            if (typeElementBindingId === '' && typeResult.bindings.length > 0) {
                onSelect(typeResult.bindings[0].id);
            }
        }

        setIsLoading(false);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (typeElementId) {
            fetchType();
        } else {
            setBindings([]);
            setIsLoading(false);
        }
    }, [typeElementId]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <FormGroup
            label={DATA_ACTION_TYPE_BINDING_LABEL[crudOperationType]}
            htmlFor="type-bindings"
            isRequired
            validationMessage={validationMessage}
            isValid={isValid}
            showValidation={showValidation}
        >
            <select
                id="type-bindings"
                value={typeElementBindingId}
                onChange={(e) => onSelect(e.target.value)}
                className="form-control form-control-width"
            >
                {bindings.length === 0 ? <option value="">No available option</option> : null}
                {bindings.map((binding) => (
                    <option key={binding.id} value={binding.id}>
                        {binding.developerName}
                    </option>
                ))}
            </select>
        </FormGroup>
    );
};

export default TypeBindingDropdown;
