import Table from '../../generic/Table';
import { useComponents } from './CustomPageComponentsProvider';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import type { CustomPageComponentDependent } from '../../../sources/customPageComponents';
import { isEmptyGuid } from '../../../utils/guid';
import translations from '../../../translations';

interface Props {
    isDeletionModal?: boolean;
    container: HTMLDivElement | null;
}

const renderDependentsTable = (dependentList: CustomPageComponentDependent[], caption: string) => (
    <Table
        items={dependentList}
        columns={[
            {
                renderHeader: () => translations.COMMON_TABLE_flow_id,
                renderCell: ({ item }) => (isEmptyGuid(item.flowId ?? '') ? '' : item.flowId),
            },
            {
                renderHeader: () => translations.COMMON_TABLE_flow_name,
                renderCell: ({ item }) => item.flowName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_map_element_id,
                // Due to a dependency bug that doesn't clear map element dependencies after flow deletion,
                // we only show the map element id if the flow id exists
                renderCell: ({ item }) =>
                    isEmptyGuid(item.mapElementId ?? '') || isEmptyGuid(item.flowId ?? '')
                        ? ''
                        : item.mapElementId,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_map_element_name,
                renderCell: ({ item }) => item.mapElementName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_page_id,
                renderCell: ({ item }) => item.pageId,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_page_name,
                renderCell: ({ item }) => item.pageName,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_page_component_id,
                renderCell: ({ item }) => item.pageComponentId,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_page_component_name,
                renderCell: ({ item }) => item.pageComponentName,
            },
        ]}
        caption={caption}
    />
);

const ComponentsDependentModal = ({ isDeletionModal = true, container }: Props) => {
    const {
        closeDependentModal,
        standardComponentOverridden,
        connectedDependentsList,
        disconnectedDependentsList,
        deleteComponent,
        pendingDeleteComponent,
        editingComponent,
        saveEditingComponent,
        components,
    } = useComponents();

    let messages: (string | JSX.Element)[] = [];
    let confirm: (() => void) | undefined;

    if (isDeletionModal && pendingDeleteComponent && disconnectedDependentsList) {
        messages = [
            `Are you sure you want to delete the "${
                pendingDeleteComponent.developerName ?? ''
            }" component?`,
            'This cannot be undone.',
        ];

        if (disconnectedDependentsList.length > 0) {
            messages = [
                ...messages,
                renderDependentsTable(
                    disconnectedDependentsList,
                    'The following elements use this component',
                ),
                'If you continue, these elements may not work as expected.',
                'Are you sure you wish to continue?',
            ];
        }

        confirm = () => deleteComponent(pendingDeleteComponent.id);
    }

    if (
        !isDeletionModal &&
        editingComponent &&
        (disconnectedDependentsList || standardComponentOverridden || connectedDependentsList)
    ) {
        const previousComponent = components.find(
            (component) => component.id === editingComponent.id,
        );
        if (previousComponent) {
            messages = [
                `Are you sure you want to change the key of the "${
                    editingComponent.developerName ?? ''
                }" component from "${previousComponent?.key}" to "${editingComponent.key}"?`,
            ];
        } else {
            messages = [
                `Are you sure you want to set the key of the "${
                    editingComponent.developerName ?? ''
                }" component to "${editingComponent.key}"?`,
            ];
        }

        if (disconnectedDependentsList) {
            messages = [
                ...messages,
                'This will disconnect this component from any page components that currently use this key.',
                renderDependentsTable(
                    disconnectedDependentsList,
                    'The following elements currently use this component',
                ),
            ];
        }

        if (standardComponentOverridden) {
            messages = [
                ...messages,
                `This component is now using the key of the standard ${standardComponentOverridden} page component.`,
                'This will override the behavior of this page component for all pages in this tenant.',
            ];
        }

        if (connectedDependentsList) {
            messages = [
                ...messages,
                'This will connect this component to any page components that currently use this key.',
                renderDependentsTable(
                    connectedDependentsList,
                    'The following elements will now use this component',
                ),
            ];
        }

        messages = [...messages, 'Are you sure you wish to continue?'];
        confirm = () => saveEditingComponent(true);
    }

    return (
        <ConfirmModal
            title={isDeletionModal ? 'Delete component' : 'Save component'}
            messages={messages}
            onCancel={closeDependentModal}
            onConfirm={confirm}
            buttonStyle={isDeletionModal ? 'danger' : 'primary'}
            buttonCaption={isDeletionModal ? 'Delete' : 'Save'}
            // Show the modal if there is
            // an array of disconnected dependents or
            // an array of connected dependents or
            // a standard component is being overridden.
            show={
                !!disconnectedDependentsList ||
                !!connectedDependentsList ||
                !!standardComponentOverridden
            }
            container={container}
        />
    );
};

export default ComponentsDependentModal;
