import type { KeyboardEvent, MouseEvent } from 'react';
import type { NavigationItemAPI } from '../../../../types';
import Glyphicon from '../../../generic/Glyphicon';
import NavigationItem from '../NavigationItem';
import type { NavigationElement } from '../utils';

type Props = {
    navigationElement: NavigationElement;
    onMoveNavItem: (parent: NavigationItemAPI, draggedId: string, overIndex: number) => void;
    onClickNavItem: (
        e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
        itemId: string | null,
    ) => void;
    onAddNavItem: (
        id: string,
        event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
    ) => void;
    onFindNavItem: (
        id: string | null,
        items: NavigationItemAPI[] | undefined | null,
    ) => NavigationItemAPI | undefined;
};

const NavigationPreview = ({
    navigationElement,
    onMoveNavItem,
    onClickNavItem,
    onAddNavItem,
    onFindNavItem,
}: Props) => {
    const navigationItems = navigationElement?.navigationItems ?? [];

    const items = navigationItems
        .sort((item1, item2) => {
            return item1.order - item2.order;
        })
        .map((item) => {
            return (
                <NavigationItem
                    key={item.id}
                    parent={null}
                    items={navigationItems}
                    item={item}
                    isTopLevel={true}
                    moveItem={onMoveNavItem}
                    itemClick={onClickNavItem}
                    addItem={onAddNavItem}
                    findItem={onFindNavItem}
                />
            );
        });

    items.push(
        <li key={'add-item'} className="add-item">
            <div
                onClick={(e) => onAddNavItem('', e)}
                onKeyUp={(e) => onAddNavItem('', e)}
                // biome-ignore lint/a11y/useSemanticElements: <explanation>
                role="button"
                tabIndex={0}
            >
                <span className="icon-wrapper">
                    <Glyphicon glyph="plus" title="Add Navigation Item" />
                </span>
            </div>
        </li>,
    );

    return (
        <>
            <h3>Preview</h3>
            <p>Drag & drop to move navigation items. Click the plus icon to add new items</p>
            <nav className="navbar navbar-inverse navbar-editor">
                <div className="navbar-header">
                    <span
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#"
                    >
                        <span className="sr-only">Toggle Navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </span>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav list-wrapper">{items}</ul>
                </div>
            </nav>
        </>
    );
};

export default NavigationPreview;
