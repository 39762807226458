import { useComponents } from './CustomPageComponentsProvider';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import DependencyTable from '../../generic/DependencyTable';
import ComponentsDependentModal from './CustomPageComponentsDependentModal';
import { FileArrowDown, Trash, Warning } from '@phosphor-icons/react';
import { componentRegistry } from '../../page-editor/registry';
import translations from '../../../translations';
import Sortable from '../../generic/Sortable';
import Table, { type TableColumnList } from '../../generic/Table';
import PageHeader from '../../generic/PageHeader';
import FileDrop from '../../generic/upload/FileDrop';
import { useRef } from 'react';
import type { CustomPageComponentAPI } from '../../../sources/customPageComponents';
import type { OrderDirection } from '../../../types';

const ComponentsList = (_: { screen: string }) => {
    const {
        components,
        setCurrentScreen,
        setEditingComponent,
        COMPONENT_SCREENS,
        exportComponent,
        tryImportComponent,
        fetchPageComponents,
        editNewComponent,
        paging,
        updatePaging,
        conflict,
        pendingImport,
        closeConflictModal,
        confirmImportOverwrite,
        tryDeleteComponent,
        componentsLoading,
    } = useComponents();

    const modalContainerRef = useRef(null);

    const columns: TableColumnList<CustomPageComponentAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={
                        paging.orderBy === 'developerName' && paging.orderDirection
                            ? paging.orderDirection
                            : null
                    }
                    onSort={(direction) => onSort({ orderBy: 'developerName', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    title={item.developerName || undefined}
                    onClick={() => {
                        setCurrentScreen(COMPONENT_SCREENS.componentDetail);
                        setEditingComponent(item);
                    }}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={
                        paging.orderBy === 'dateModified' && paging.orderDirection
                            ? paging.orderDirection
                            : null
                    }
                    onSort={(direction) => onSort({ orderBy: 'dateModified', direction })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) => {
                if (item.dateModified) {
                    return new Date(item.dateModified).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    });
                }
                return null;
            },
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_key,
            renderCell: ({ item }) =>
                componentRegistry[item.key.toUpperCase()] ? (
                    <span title="This component overrides a standard component">
                        {item.key}
                        <Warning size={20} className="warning-icon" />
                    </span>
                ) : (
                    item.key
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => item.developerSummary,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Export ${item.developerName}`}
                        className="table-icon"
                        onClick={() => exportComponent(item)}
                        aria-label={`Export ${item.developerName}`}
                        type="button"
                    >
                        <FileArrowDown />
                    </button>
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        onClick={() => tryDeleteComponent(item)}
                        aria-label={`Delete ${item.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '6rem',
        },
    ];

    const onSort = ({ orderBy, direction }: { orderBy: string; direction: OrderDirection }) =>
        updatePaging({
            ...paging,
            orderBy,
            orderDirection: direction,
        });

    const onPage = (updatedPage: number) =>
        updatePaging({
            ...paging,
            page: updatedPage,
        });

    const onFilter = (searchQuery: string) =>
        updatePaging({
            ...paging,
            page: 1,
            search: searchQuery,
        });

    return (
        <div className="admin-page components flow-wrapper" ref={modalContainerRef}>
            <PageHeader
                title="Components"
                onAdd={() => {
                    setCurrentScreen(COMPONENT_SCREENS.componentDetail);
                    editNewComponent();
                }}
                onRefresh={() => fetchPageComponents(paging)}
                onSearch={onFilter}
                searchQuery={paging.search}
                searchPlaceholderText="Search"
                addText="New Component"
                refreshTitle="Refresh Results"
            >
                <FileDrop
                    className="margin-bottom-large"
                    placeholder="Drop exported component file here, or click to browse to import"
                    onChange={tryImportComponent}
                    fileTypes=".component"
                />
            </PageHeader>

            <ConfirmModal
                title="Overwrite existing component?"
                messages={
                    pendingImport && conflict
                        ? [
                              `We have detected that this import will replace a Component that already
                        exists in this Tenant.`,
                              <DependencyTable
                                  key="conflict"
                                  dependents={{
                                      existingElements: {
                                          flows: [],
                                          customPageComponentElements: [conflict],
                                          groupElements: [],
                                          macroElements: [],
                                          mapElements: [],
                                          navigationElements: [],
                                          pageElements: [],
                                          serviceElements: [],
                                          tagElements: [],
                                          typeElements: [],
                                          valueElements: [],
                                          identityProviderElements: [],
                                      },
                                  }}
                              />,
                          ]
                        : []
                }
                onCancel={closeConflictModal}
                onConfirm={confirmImportOverwrite}
                show={!!conflict}
                container={modalContainerRef.current}
            />
            <ComponentsDependentModal container={modalContainerRef.current} />

            <Table
                wrapperClassName="margin-top"
                columns={columns}
                items={components}
                isLoading={componentsLoading}
                pagination={{
                    page: paging.page as number,
                    total: paging.total,
                    pageSize: paging.pageSize as number,
                    changePage: onPage,
                }}
            />
        </div>
    );
};

export default ComponentsList;
