import { useState, createContext, useContext, type ReactNode } from 'react';
import { getAllValuesV2, deleteValue as deleteValueRequest } from '../../../sources/value';
import {
    ElementType,
    type AddNotification,
    type Filter,
    type OrderDirection,
    type Paging,
    type ValueElementResponseAPI,
} from '../../../types';

interface Pagination extends Paging {
    search: string;
    orderBy: string;
    orderDirection: OrderDirection;
}

interface Context {
    tenantId: string;
    currentScreen: string;
    fetchValues: (filters: Omit<Filter, 'limit'>) => Promise<void>;
    values: ValueElementResponseAPI[];
    valueToEdit: ValueElementResponseAPI;
    editValue: (value: ValueElementResponseAPI) => void;
    addNotification: AddNotification;
    returnToValueList: () => void;
    createValue: () => void;
    valuesLoading: boolean;
    valueToDelete: ValueElementResponseAPI | null;
    setValueToDelete: (value: ValueElementResponseAPI) => void;
    deleteValue: () => Promise<void>;
    paging: Pagination;
}

interface Props {
    tenantId: string;
    addNotification: AddNotification;
    children: ReactNode;
}

const Context = createContext<Context | undefined>(undefined);

const ValuesProvider = ({ addNotification, tenantId, children }: Props) => {
    const VALUE_SCREENS = {
        valueList: 'valueList',
        valueDetail: 'valueDetail',
    };

    const initialValueToEdit: ValueElementResponseAPI = {
        id: '',
        access: 'PRIVATE',
        contentFormat: '',
        contentType: null,
        defaultContentValue: null,
        defaultObjectData: [],
        elementType: ElementType.Value,
        developerName: '',
        developerSummary: '',
        initializationOperations: [],
        isEncrypted: false,
        typeElementId: null,
        typeElementDeveloperName: null,
        updateByName: false,
        isSecret: false,
        isVersionless: false,
        dateCreated: '',
        dateModified: '',
        whoCreated: null,
        whoModified: null,
        whoOwner: null,
        hasValue: false,
    };

    const [currentScreen, setCurrentScreen] = useState(VALUE_SCREENS.valueList);
    const [values, setValues] = useState<ValueElementResponseAPI[]>([]);
    const [valuesLoading, setValuesLoading] = useState(true);
    const [valueToEdit, setValueToEdit] = useState<ValueElementResponseAPI>(initialValueToEdit);
    const [valueToDelete, setValueToDelete] = useState<ValueElementResponseAPI | null>(null);

    const [paging, updatePaging] = useState<Pagination>({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });

    const fetchValues = async (filters: Filter) => {
        setValuesLoading(true);

        try {
            const { items, _meta } = await getAllValuesV2({
                ...filters,
                // Endpoint doesn't accept page size, so map it to limit
                limit: filters.pageSize ?? paging.pageSize,
            });

            setValues(items);

            const { search, orderBy, orderDirection } = filters;

            updatePaging({
                ..._meta,
                search: search ?? '',
                orderBy: orderBy ?? '',
                orderDirection: orderDirection ?? 'ASC',
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setValuesLoading(false);
        }
    };

    const deleteValue = async () => {
        const { id, developerName } = valueToDelete as ValueElementResponseAPI;

        try {
            await deleteValueRequest(id);

            const remainingValues = values.filter((value) => value.id !== id);

            setValues(remainingValues);
            returnToValueList();
            addNotification({
                type: 'success',
                message: `Value: ${developerName} has been successfully deleted.`,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    const editValue = (value: ValueElementResponseAPI) => {
        setCurrentScreen(VALUE_SCREENS.valueDetail);
        setValueToEdit(value);
    };

    const createValue = () => {
        setCurrentScreen(VALUE_SCREENS.valueDetail);
        setValueToEdit(initialValueToEdit);
    };

    const returnToValueList = () => {
        setCurrentScreen(VALUE_SCREENS.valueList);
    };

    const contextValue: Context = {
        tenantId,
        currentScreen,
        fetchValues,
        values,
        valueToEdit,
        editValue,
        addNotification,
        returnToValueList,
        createValue,
        valuesLoading,
        valueToDelete,
        setValueToDelete,
        deleteValue,
        paging,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useValues = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useValues must be used within a ValuesProvider');
    }
    return context;
};

export { ValuesProvider, useValues };
