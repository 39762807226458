import { createElement, type MouseEvent, type KeyboardEvent } from 'react';
import Glyphicon from '../../generic/Glyphicon';
import { useDrag, useDrop } from 'react-dnd';
import type { NavigationItemAPI } from '../../../types';

import '../../../../../css/flow/navigation-editor.less';

type Props = {
    isTopLevel: boolean;
    items: NavigationItemAPI[];
    item: NavigationItemAPI;
    parent: NavigationItemAPI | null;
    findItem: (
        id: string | null,
        items: NavigationItemAPI[] | undefined | null,
    ) => NavigationItemAPI | undefined;
    itemClick: (
        e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
        itemId: string | null,
    ) => void;
    moveItem: (parent: NavigationItemAPI, draggedId: string, overIndex: number) => void;
    addItem: (
        id: string,
        event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
    ) => void;
};

type DnDNavigationItem = {
    id: string;
    order: number;
    parent: NavigationItemAPI | null;
};

const renderNavigationItem = (props: Props) =>
    createElement(NavigationItem, { ...props, key: props.item.id });

const NavigationItem = ({
    item,
    findItem,
    items,
    parent,
    itemClick,
    isTopLevel,
    moveItem,
    addItem,
}: Props) => {
    const [_, drop] = useDrop<DnDNavigationItem>(
        () => ({
            accept: 'NAVIGATION_ITEM',
            hover: (_, monitor) => {
                const { id: draggedId, parent } = monitor.getItem<DnDNavigationItem>();
                const overId = item.id;

                if (draggedId !== overId && monitor.isOver({ shallow: true })) {
                    const item = findItem(overId, items);

                    if (item && parent) {
                        const overIndex = item.order;
                        moveItem(parent, draggedId, overIndex);
                    }
                }
            },
            canDrop: (props, monitor) => {
                const { parent } = monitor.getItem<DnDNavigationItem>();
                return parent !== null && findItem(props.id, parent.navigationItems) !== undefined;
            },
        }),
        [],
    );

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'NAVIGATION_ITEM',
            item: { id: item.id, order: item.order, parent: parent },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [item.order, parent],
    );

    const childMenuClass = isTopLevel
        ? ['navigation-item', 'dropdown']
        : ['navigation-item', 'dropdown-submenu'];
    const navigationItemIcon = isTopLevel ? (
        <span>
            {item.label} <b className="caret" />
        </span>
    ) : (
        item.label
    );

    const childItems = item.navigationItems || [];
    const sortedChildItems = childItems
        .sort((item1, item2) => {
            return item1.order - item2.order;
        })
        .map((child) => {
            return renderNavigationItem({
                items: items,
                parent: item,
                item: child,
                isTopLevel: false,
                moveItem: moveItem,
                itemClick: itemClick,
                addItem: addItem,
                findItem: findItem,
            });
        });

    sortedChildItems.push(
        <li key="add-item" className="add-item">
            <div
                onClick={(e) => addItem(item.id || '', e)}
                onKeyUp={(e) => addItem(item.id || '', e)}
                // biome-ignore lint/a11y/useSemanticElements: <explanation>
                role="button"
                tabIndex={item.order}
            >
                <span className="icon-wrapper">
                    <Glyphicon glyph="plus" />
                </span>
            </div>
        </li>,
    );

    const opacity = isDragging ? 0 : 1;

    const navigationItem = (
        <li
            ref={drop}
            key={item.id}
            className={childMenuClass.join(' ')}
            style={{ opacity: opacity }}
        >
            <div
                ref={drag}
                className="click-wrapper"
                onClick={(e) => itemClick(e, item.id)}
                onKeyUp={(e) => itemClick(e, item.id)}
                // biome-ignore lint/a11y/useSemanticElements: <explanation>
                role="button"
                tabIndex={item.order}
            >
                <span className="item-wrapper">{navigationItemIcon}</span>
                <ul className="dropdown-menu">{sortedChildItems}</ul>
            </div>
        </li>
    );

    return navigationItem;
};

export default NavigationItem;
