import type { _DeepPartialObject } from 'chart.js/dist/types/utils';
import type { DateFilterItem } from '../components/developerDashboard/InsightsDateFilter';
import { TAB_TYPES } from '../constants';
import translations from '../translations';
import type { ChartDataPoint, FlowUsageDashboardResponse, TimeFilters } from '../types/dashboard';
import { generateRouteUrl } from './routing';
import { getTenantId } from './tenant';
import type {
    ActiveElement,
    ChartEvent,
    CoreChartOptions,
    DatasetChartOptions,
    ElementChartOptions,
    LineControllerChartOptions,
    PluginChartOptions,
    ScaleChartOptions,
} from 'chart.js/auto';
import { getLocale } from './date';

export const onGoToFlow = (flowId: string, navigate: (route: string) => void) => {
    const route = generateRouteUrl({
        tabType: TAB_TYPES.flow,
        tenantId: getTenantId(),
        options: { elementId: flowId },
    });

    if (!route) {
        return;
    }

    navigate(route);
};

export const getLaunchesPercentage = (count: number, flows: FlowUsageDashboardResponse[]) => {
    const totalCount = flows.reduce((accumulator, flow) => accumulator + flow.flowLaunchTotal, 0);
    const percentage = (count / totalCount) * 100;
    return Math.round((percentage + Number.EPSILON) * 100) / 100;
};

export const sortFlowLaunchesByCount = (flowsLaunches: FlowUsageDashboardResponse[]) =>
    flowsLaunches.sort((a, b) => {
        if (a.flowLaunchTotal > b.flowLaunchTotal) {
            return -1;
        }

        if (a.flowLaunchTotal < b.flowLaunchTotal) {
            return 1;
        }

        return 0;
    });

export const formatChartDate = (date: Date, dateRange: TimeFilters) => {
    if (dateRange === 'year') {
        return date.toLocaleString(getLocale(), {
            month: 'short',
            year: 'numeric',
        });
    }

    if (dateRange === 'month' || dateRange === 'week') {
        return date.toLocaleString(getLocale(), {
            day: '2-digit',
            month: 'short',
        });
    }

    // This is for if we want hourly (day) but with the month and day included.
    if (dateRange === 'long-day') {
        return date.toLocaleString(getLocale(), {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            hourCycle: 'h12',
        });
    }

    return date.toLocaleString(getLocale(), {
        weekday: 'short',
        hour: 'numeric',
        hourCycle: 'h12',
    });
};

// Counts back a number of days from the toDate (right now) based on the desired time range.
// We use specific day numbers here to maych the back end, but technically 365 and 30 for year and month
// could cause some issues with accuracy. For now this is fine though.
export const getFromDate = (dateRange: TimeFilters, toDate: Date) => {
    const fromDate = new Date(toDate);

    if (dateRange === 'year') {
        fromDate.setUTCFullYear(fromDate.getUTCFullYear() - 1);
        fromDate.setUTCDate(1);
        fromDate.setUTCHours(0, 0, 0, 0); // Remove the hours so we start the block from the start of the day.
    } else if (dateRange === 'month') {
        fromDate.setUTCDate(fromDate.getUTCDate() - 29);
        fromDate.setUTCHours(0, 0, 0, 0); // Remove the hours so we start the block from the start of the day.
    } else if (dateRange === 'week') {
        fromDate.setUTCDate(fromDate.getUTCDate() - 7);
        fromDate.setUTCHours(0, 0, 0, 0); // Remove the hours so we start the block from the start of the day.
    } else {
        fromDate.setUTCDate(fromDate.getUTCDate() - 1);
        fromDate.setUTCMinutes(0, 0, 0); // Remove the minutes so we start the block from the start of the hour.
    }

    return fromDate;
};

export const convertDateToUnixTime = (date: Date) => Math.floor(new Date(date).getTime() / 1000);

export const convertDateFromUnixTime = (date: number) => new Date(date * 1000);

export const getDashboardLineChartOptions = (
    onHandlePointClick: (event: ChartEvent, elements: ActiveElement[]) => void = () => null,
): _DeepPartialObject<
    CoreChartOptions<'line'> &
        ElementChartOptions<'line'> &
        PluginChartOptions<'line'> &
        DatasetChartOptions<'line'> &
        ScaleChartOptions<'line'> &
        LineControllerChartOptions
> => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        elements: { point: { radius: 2, hoverRadius: 5, hitRadius: 6 } }, // This can be adjusted based on feedback.
        plugins: { legend: { display: false } },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value: number | string) => {
                        if ((value as number) % 1 === 0) {
                            return value;
                        }
                        return null;
                    },
                    color: '#9f9f9f',
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#9f9f9f',
                },
            },
        },
        onClick: onHandlePointClick,
    };
};

// A common filter items configuration we use on a few different pages.
export const insightDateFilterItems: DateFilterItem[] = [
    {
        label: translations.DASHBOARD_filter_last_day,
        value: 'day',
        tooltip: translations.DASHBOARD_filter_last_day_tooltip,
    },
    {
        label: translations.DASHBOARD_filter_last_week,
        value: 'week',
        tooltip: translations.DASHBOARD_filter_last_week_tooltip,
    },
    {
        label: translations.DASHBOARD_filter_last_month,
        value: 'month',
        tooltip: translations.DASHBOARD_filter_last_month_tooltip,
    },
    {
        label: translations.DASHBOARD_filter_last_year,
        value: 'year',
        tooltip: translations.DASHBOARD_filter_last_year_tooltip,
    },
];

export const onHandlePointClick = (
    datapoint: ChartDataPoint | undefined,
    dateFilter: TimeFilters,
    setDateFilter: (dateFilter: TimeFilters) => void,
    setCustomToDate: (customToDate: Date) => void,
) => {
    if (!datapoint?.rawDate) {
        return;
    }

    // There's no higher resolution than hourly over a whole day so do nothing.
    if (dateFilter === 'day') {
        return;
    }

    const newDate = datapoint.rawDate;

    if (dateFilter === 'week') {
        setDateFilter('day');
    } else if (dateFilter === 'month') {
        setDateFilter('week');
    } else if (dateFilter === 'year') {
        setDateFilter('month');

        // It feels weird that zooming into a month would start it part way through the month. Set the customToDate to the last day of the current month.
        // Setting the date to 0 is the same as the last day of the previous month so adding one to the month will make it the current instead.
        newDate.setMonth(newDate.getMonth() + 1, 0);
    }

    newDate.setHours(23, 59, 59);

    setCustomToDate(new Date(newDate));
};

// The increment value needs to be calculated every time because of months having different days.
export const getSecondsToIncrement = (dateRange: TimeFilters, currentStep: Date) => {
    const hourInSeconds = 60 * 60;
    const dayInSeconds = hourInSeconds * 24;

    if (dateRange === 'day') {
        return hourInSeconds;
    }

    if (dateRange === 'week' || dateRange === 'month') {
        return dayInSeconds;
    }

    // Calculating the steps for a month block is a bit more complicated as months have a variable number of days.
    const nextStep = new Date(currentStep);
    nextStep.setUTCMonth(nextStep.getUTCMonth() + 1, 1);
    nextStep.setUTCHours(0, 0, 0, 0);

    const delta = convertDateToUnixTime(nextStep) - convertDateToUnixTime(currentStep);
    return delta;
};
