import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import NavigationOverrideDetails from '../../../../../ts/components/flow/elementConfigurations/common/navigationOverrides/NavigationOverrideDetails';
import MacroDetails from './MacroDetails';
import OperationDetails from './OperationDetails';
import OperatorConfiguration from './OperatorConfiguration';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

/**
 * Handles switching between the operator configuration screens
 */
const OperatorRouter = () => {
    const { currentScreen } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <OperatorConfiguration screen={screens.operator} />
            <OperationDetails screen={screens.operationDetails} />
            <MacroDetails screen={screens.macroDetails} />
            <NavigationOverrideDetails screen={screens.navigationOverrides} />
        </ScreenRouter>
    );
};

export default OperatorRouter;
