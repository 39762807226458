import { type MouseEvent, useEffect, useState } from 'react';
import '../../../../../css/flow/shared-elements.less';
import { SYSTEM_TYPE_IDS, SYSTEM_VALUE_IDS, FRIENDLY_DEPENDENCY_NAMES } from '../../../constants';
import { getFlowSharedElements, addElement, removeElement } from '../../../sources/flow';
import { isNullOrEmpty } from '../../../utils/guard';
import { mapFriendlyNameToDisplay } from '../../../../js/utils/DisplayValueMappings';
import ButtonDefault from '../../../components/buttons/ButtonDefault';
import Modal from '../../../components/generic/modal/GenericModal';
import Loader from '../../../components/loader/Loader';
import SharedElementsTable from './SharedElementsTable';
import type { AddNotification, ElementAPI } from '../../../types';
import TabContentControls from './TabContentControls';
import SharedElementTab from './SharedElementTab';
import { getAllValues } from '../../../sources/value';
import ImportedElements from './ImportedElements';
import { getAllTypes } from '../../../sources/type';
import { getAllPages } from '../../../sources/page';
import { macroSource } from '../../../sources/macro';
import { getAllServices } from '../../../sources/service';

type Props = {
    tenantId: string;
    flowId: string;
    addNotification: AddNotification;
    close: () => void;
    container: HTMLElement | null;
};

const SharedElementsModal = ({ tenantId, flowId, addNotification, close, container }: Props) => {
    const [activeTab, setActiveTab] = useState<string>(FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT);
    const [showingImportPage, setShowingImportPage] = useState(false);
    const [tableData, setTableData] = useState<ElementAPI[]>([]);
    const [filteredTableData, setFilteredTableData] = useState<ElementAPI[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const SYSTEM_VALUE_AND_TYPE_IDS = SYSTEM_VALUE_IDS.concat(SYSTEM_TYPE_IDS);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        loadMainPage();
    }, [activeTab]);

    const getElementsInFlow = async () => {
        try {
            const response = await getFlowSharedElements(flowId, activeTab);

            const filteredResponse = filterOutSystemElements(response);
            setTableData(filteredResponse);
            setFilteredTableData(filteredResponse);
            setIsLoading(false);
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    const importElementIntoFlow = (id: string) => {
        addElement(id, flowId, activeTab, tenantId)
            .then(loadMainPage)
            .catch(({ message }) =>
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                }),
            );
    };

    const filterOutSystemElements = (newData: ElementAPI[]) => {
        return newData.filter((item) => !SYSTEM_VALUE_AND_TYPE_IDS.includes(item.id));
    };

    const removeElementFromFlow = (id: string) => {
        removeElement(id, flowId, activeTab, tenantId)
            .then(loadMainPage)
            .catch(({ message }) =>
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                }),
            );
    };

    const loadImportPage = () => {
        setShowingImportPage(true);
    };

    const loadMainPage = () => {
        setIsLoading(true);
        setShowingImportPage(false);
        getElementsInFlow();
    };

    const changeActiveTab = (e: MouseEvent<HTMLButtonElement>, tabValue: string) => {
        if (tabValue !== activeTab) {
            e.preventDefault();
            e.stopPropagation();

            setActiveTab(tabValue);
        }
    };

    const filterBySearchTerms = (searchInput: string) => {
        if (isNullOrEmpty(searchInput) && tableData.length !== filteredTableData.length) {
            setFilteredTableData(tableData);
        } else {
            const filteredTableData = tableData.filter((row) => {
                const name = row.developerName ? row.developerName.toLowerCase() : '';
                return name.includes(searchInput.toLowerCase());
            });

            setFilteredTableData(filteredTableData);
        }
    };

    const renderLoadingSection = <Loader message="Loading data..." />;

    const importPageBody = () => (
        <>
            <ImportedElements
                elementType={activeTab}
                importElement={importElementIntoFlow}
                addNotification={addNotification}
                isOpen={activeTab === FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT}
                onFetch={getAllValues}
            />
            <ImportedElements
                elementType={activeTab}
                importElement={importElementIntoFlow}
                addNotification={addNotification}
                isOpen={activeTab === FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT}
                onFetch={getAllTypes}
            />
            <ImportedElements
                elementType={activeTab}
                importElement={importElementIntoFlow}
                addNotification={addNotification}
                isOpen={activeTab === FRIENDLY_DEPENDENCY_NAMES.PAGE}
                onFetch={getAllPages}
            />
            <ImportedElements
                elementType={activeTab}
                importElement={importElementIntoFlow}
                addNotification={addNotification}
                isOpen={activeTab === FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT}
                onFetch={macroSource.getAll}
            />
            <ImportedElements
                elementType={activeTab}
                importElement={importElementIntoFlow}
                addNotification={addNotification}
                isOpen={activeTab === FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT}
                onFetch={getAllServices}
            />
        </>
    );

    const importPageContent = (
        <Modal
            renderHeader={() => (
                <h4 className="modal-title" data-testid="import-header">
                    Add {mapFriendlyNameToDisplay(activeTab)} To Flow
                </h4>
            )}
            renderBody={importPageBody}
            renderFooter={() => (
                <ButtonDefault title="Close Modal" onClick={loadMainPage}>
                    Back
                </ButtonDefault>
            )}
            container={container}
            onHide={close}
        />
    );

    const tabContent = () => {
        return isLoading ? (
            renderLoadingSection
        ) : (
            <>
                <TabContentControls
                    searchText={searchText}
                    setSearchText={setSearchText}
                    filterBySearchTerms={filterBySearchTerms}
                    loadImportPage={loadImportPage}
                    loadMainPage={loadMainPage}
                />
                <SharedElementsTable
                    tableData={filteredTableData}
                    removeElement={removeElementFromFlow}
                />
            </>
        );
    };

    const body = () => {
        return (
            <>
                <ul className="nav nav-tabs">
                    <SharedElementTab
                        activeTab={activeTab}
                        dependencyName={FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT}
                        changeActiveTab={changeActiveTab}
                    />
                    <SharedElementTab
                        activeTab={activeTab}
                        dependencyName={FRIENDLY_DEPENDENCY_NAMES.PAGE}
                        changeActiveTab={changeActiveTab}
                    />
                    <SharedElementTab
                        activeTab={activeTab}
                        dependencyName={FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT}
                        changeActiveTab={changeActiveTab}
                    />
                    <SharedElementTab
                        activeTab={activeTab}
                        dependencyName={FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT}
                        changeActiveTab={changeActiveTab}
                    />
                    <SharedElementTab
                        activeTab={activeTab}
                        dependencyName={FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT}
                        changeActiveTab={changeActiveTab}
                    />
                </ul>
                {tabContent()}
            </>
        );
    };

    const mainContent = (
        <Modal
            className="config-modal"
            renderHeader={() => <h4 className="modal-title">Shared Elements</h4>}
            renderBody={body}
            renderFooter={() => (
                <ButtonDefault title="Close Modal" onClick={close}>
                    Close
                </ButtonDefault>
            )}
            container={container}
            onHide={close}
        />
    );

    return showingImportPage ? importPageContent : mainContent;
};

export default SharedElementsModal;
