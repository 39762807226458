import {
    type ChangeEventHandler,
    type KeyboardEventHandler,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import '../../../css/apiTool.css';
import '../../../css/notification.less';
import { notifyError, notifySuccess } from '../../js/actions/reduxActions/notification';
import { sendRequest } from '../sources/apiTool';
import translations from '../translations';
import { HttpMethod } from '../types';
import { stringReplace } from '../utils';
import ButtonDanger from './buttons/ButtonDanger';
import ButtonPrimary from './buttons/ButtonPrimary';
import CodeEditor from './generic/CodeEditor';
import Loader from './loader/Loader';
import { fetchApiEndpoints } from '../sources/apiTool';

const mapStateToProps = ({
    selectedFlow,
}: {
    selectedFlow?: {
        id: string;
        editingToken: string;
        versionId: string;
    };
}) => ({
    selectedFlow,
});

const mapDispatchToProps = {
    notifyError,
    notifySuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ApiToolProps = ConnectedProps<typeof connector>;

const ApiTool = ({ notifyError, notifySuccess, selectedFlow }: ApiToolProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [contentType, setContentType] = useState<'json' | 'html' | 'text'>('json');
    const [requestBody, setRequestBody] = useState('');
    const [responseBody, setResponseBody] = useState('');
    const [requestUrl, setRequestUrl] = useState('');
    const [isLoadingEndpoints, setIsLoadingEndpoints] = useState(false);
    const [endpoints, setEndpoints] = useState<Record<string, string>>({});

    useEffect(() => {
        const fetchEndpoints = async () => {
            try {
                setIsLoadingEndpoints(true);
                const endpoints = await fetchApiEndpoints();
                setEndpoints(endpoints);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoadingEndpoints(false);
            }
        };

        fetchEndpoints();
    }, [notifyError]);

    const handleRequest = async (method: HttpMethod) => {
        if (requestUrl.trim() === '') {
            return;
        }

        try {
            setIsLoading(true);

            const responseData = await sendRequest(
                requestUrl,
                method,
                method === 'GET' ? null : requestBody,
            );

            const stringifiedResponse = (() => {
                const result = JSON.stringify(responseData, null, 4);
                // Guard against values that get returned unchanged
                return typeof result !== 'string' ? '' : result;
            })();

            // If we have an Object, it's probably JSON...
            if (responseData !== null && typeof responseData === 'object') {
                setResponseBody(stringifiedResponse);
                setContentType('json');
            } else if (typeof responseData === 'string') {
                /**
                 * If the response is not JSON then it could either be
                 * a string describing a Flow package or the HTML of
                 * a player
                 */
                try {
                    /**
                     * String can be parsed as JSON, so stringify it again
                     * to unescape it (kind of obfuscates the package data)
                     */
                    JSON.parse(responseData);
                    setResponseBody(stringifiedResponse);
                    setContentType('json');
                } catch {
                    // String cannot be parsed as JSON, so we assume it is just HTML
                    setResponseBody(responseData);
                    setContentType('html');
                }
            } else {
                setResponseBody(stringifiedResponse);
                setContentType('text');
            }

            notifySuccess(
                stringReplace(translations.API_TOOL_request_success_message, {
                    method,
                    requestUrl,
                }),
            );
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
        if (key !== 'Enter' || requestUrl.trim() === '') {
            return;
        }

        handleRequest(HttpMethod.Get);
    };

    const handleRequestBodyChange = (value: string) => {
        setRequestBody(value);
    };

    const handleRequestUrlChange: ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
    }) => {
        setRequestUrl(value);
    };

    const urls = useMemo(
        () =>
            Object.keys(endpoints).map((endpoint) => {
                if (selectedFlow) {
                    return endpoint
                        .replace('{flow}', selectedFlow.id)
                        .replace('/flow/{id}', `/flow/${selectedFlow.id}`)
                        .replace('{version}', selectedFlow.versionId)
                        .replace('{editingToken}', selectedFlow.editingToken);
                }

                return endpoint;
            }),
        [endpoints, selectedFlow],
    );

    return (
        <div className="api-tool">
            <div className="api-endpoint">
                <input
                    data-testid="urlInput"
                    list="endpoints"
                    placeholder={
                        isLoadingEndpoints
                            ? translations.API_TOOL_url_input_loading_placeholder
                            : translations.API_TOOL_url_input_placeholder
                    }
                    className="form-control"
                    onChange={handleRequestUrlChange}
                    aria-label={translations.API_TOOL_url_input_label}
                    onKeyDown={handleKeyDown}
                />
                <datalist id="endpoints" data-testid="endpoints">
                    {urls.map((url) => (
                        <option key={url} value={url} label={url}>
                            {url}
                        </option>
                    ))}
                </datalist>
                <div className="margin-left nowrap">
                    <ButtonPrimary
                        className="request-button"
                        onClick={() => handleRequest(HttpMethod.Get)}
                    >
                        {translations.API_TOOL_get_request_button_label}
                    </ButtonPrimary>
                    <ButtonPrimary
                        className="request-button"
                        onClick={() => handleRequest(HttpMethod.Post)}
                    >
                        {translations.API_TOOL_post_request_button_label}
                    </ButtonPrimary>
                    <ButtonPrimary
                        className="request-button"
                        onClick={() => handleRequest(HttpMethod.Put)}
                    >
                        {translations.API_TOOL_put_request_button_label}
                    </ButtonPrimary>
                    <ButtonDanger
                        className="request-button"
                        onClick={() => handleRequest(HttpMethod.Delete)}
                    >
                        {translations.API_TOOL_delete_request_button_label}
                    </ButtonDanger>
                </div>
            </div>
            <div className="request-response-container">
                <div className="request">
                    <h4 className="editor-heading text-center">
                        {translations.API_TOOL_request_body_input_label}
                    </h4>
                    <CodeEditor
                        value={requestBody}
                        highlightActiveLine
                        name="request-editor"
                        onChange={handleRequestBodyChange}
                    />
                </div>
                <div className="response">
                    <h4 className="editor-heading text-center">
                        {translations.API_TOOL_response_body_input_label}
                    </h4>
                    <CodeEditor
                        mode={contentType}
                        value={responseBody}
                        highlightActiveLine
                        readOnly
                        name="response-editor"
                    />
                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    );
};

export default connector(ApiTool);
