import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { DataActionProvider } from '../contextProviders/DataActionProvider';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import DeleteRouter from './DeleteRouter';
import { CRUD_OPERATION_TYPES } from '../../../../../ts/components/flow/elementConfigurations/common/constants';

const Delete = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.delete}
            elementType={MAP_ELEMENT_TYPES.databaseDelete}
        >
            <MapElementModal>
                <DataActionProvider
                    defaultScreen={screens.delete}
                    crudOperationType={CRUD_OPERATION_TYPES.delete}
                >
                    <DeleteRouter />
                </DataActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Delete;
